<template>
  <div class="statisticsModule">
    <van-sticky z-index="1" class="statisticsModuleHeader">
      <div class="statisticsModuleTitle">
        <div class="statisticsModuleLeftReturn">
          <van-image
            class="statisticsModuleLeftReturnImg"
            fit="contain"
            :src="require('../../assets/images/left-white.png')"
            @click="leftReturn"
          />
        </div>
        <div class="statisticsModuleTitleName">
          <span>统计</span>
        </div>
      </div>
      <van-tabs
        :border="false"
        title-style="z-index:998;"
        sticky
        title-active-color="white"
        title-inactive-color="#FFE1C7"
        color="white"
        line-width="40px"
        line-height="4px"
        background="linear-gradient(to right, #ffc274, #ff5d3b)"
        v-model="active"
      >
        <van-tab title="业务统计" name="a" v-if="tempAuth0"></van-tab>
        <van-tab title="集寓报表" name="b" v-if="tempAuth1"></van-tab>
        <van-tab title="基础人事" name="c" v-if="tempAuth2"></van-tab>
        <van-tab title="财务统计" name="d" v-if="tempAuth3"></van-tab>
        <van-tab title="装修统计" name="e" v-if="tempAuth4"></van-tab>
        <van-tab title="租后统计" name="f" v-if="tempAuth5"></van-tab>
        <van-tab title="客户服务" name="g" v-if="tempAuth6"></van-tab>
        <van-tab title="业绩" name="h" v-if="tempAuth7"></van-tab>
        <van-tab title="用户画像" name="i" v-if="tempAuth8"></van-tab>
      </van-tabs>
    </van-sticky>
    <div class="statisticsModuleBody">
      <!-- 业务统计-->
      <div v-if="active && active.indexOf('a') != -1">
        <businessModule ref="businessModule"></businessModule>
      </div>
      <!-- 集寓报表-->
      <div v-if="active && active.indexOf('b') != -1">
        <setMainAnalysis ref="setMainAnalysis"></setMainAnalysis>
      </div>

      <!-- 基础人事-->
      <div v-if="active && active.indexOf('c') != -1">
        <basicPersonnelModule ref="basicPersonnelModule"></basicPersonnelModule>
      </div>
      <!-- 财务统计-->
      <div v-if="active && active.indexOf('d') != -1">
        <financialModule ref="financialModule"></financialModule>
      </div>
      <!-- 装修统计-->
      <div v-if="active && active.indexOf('e') != -1">
        <decorationStatistics ref="decorationStatistics"></decorationStatistics>
      </div>
      <!-- 租后统计-->
      <div v-if="active && active.indexOf('f') != -1">
        <afterRentStatistics ref="afterRentStatistics"></afterRentStatistics>
      </div>
      <!-- 客户服务-->
      <div v-if="active && active.indexOf('g') != -1">
        <customerService ref="customerService"></customerService>
      </div>
      <!-- 业绩-->
      <div v-if="active && active.indexOf('h') != -1">
        <achievementModule ref="achievementModule"></achievementModule>
      </div>
      <!-- 用户画像-->
      <div v-if="active && active.indexOf('i') != -1">
        <userPortrait ref="userPortrait"></userPortrait>
      </div>
    </div>

    <div style="height: 20px;width: 100%"></div>
  </div>
</template>

<script>
import financialModule from './financialModule'
import userPortrait from './userPortrait'
import achievementModule from './achievementModule'
import customerService from './customerService'
import decorationStatistics from './decorationStatistics'
import setMainAnalysis from './setMainAnalysis'
import afterRentStatistics from './afterRentStatistics'
import basicPersonnelModule from './basicPersonnelModule'
import businessModule from './businessModule'
import { Tab, Tabs, Image as VanImage, Sticky } from 'vant'
import {
  checkAndroid,
  checkIOS,
  getStaffId,
  openInWebview,
  responseUtil,
} from '../../libs/rongxunUtil'

import { userAuthList } from '../../getData/getData'
//调用android关闭页面方法*******begin*********
function backToAndroid() {
  window.himi.closeWindow()
}
//调用android关闭页面方法*******end***********
export default {
  name: 'statisticsModule',
  components: {
    financialModule,
    achievementModule,
    userPortrait,
    customerService,
    setMainAnalysis,
    decorationStatistics,
    basicPersonnelModule,
    afterRentStatistics,
    businessModule,
    [VanImage.name]: VanImage,
    [Tab.name]: Tab,
    [Tabs.name]: Tabs,
    [Sticky.name]: Sticky,
  },
  data() {
    return {
      tempAuth0: false, //业务统计
      tempAuth1: false, //集寓报表
      tempAuth2: false, //基础人事
      tempAuth3: false, //财务统计
      tempAuth4: false, //装修统计
      tempAuth5: false, //租后统计
      tempAuth6: false, //客户服务
      tempAuth7: false, //业绩
      tempAuth8: false, //用户画像

      active: 'a',
    }
  },
  created() {},
  mounted() {
    //获取传入第几个页面
    if (
      this.$route.query.dataType != undefined &&
      this.$route.query.dataType != ''
    ) {
      if (this.$route.query.dataType == 0) {
        this.active = 'a'
      } else if (this.$route.query.dataType == 1) {
        this.active = 'b'
      } else if (this.$route.query.dataType == 2) {
        this.active = 'c'
      } else if (this.$route.query.dataType == 3) {
        this.active = 'd'
      } else if (this.$route.query.dataType == 4) {
        this.active = 'e'
      } else if (this.$route.query.dataType == 5) {
        this.active = 'f'
      } else if (this.$route.query.dataType == 6) {
        this.active = 'g'
      } else if (this.$route.query.dataType == 7) {
        this.active = 'h'
      } else if (this.$route.query.dataType == 8) {
        this.active = 'i'
      }
    }

    this.getStaffId()
  },
  methods: {
    leftReturn() {
      if (openInWebview()) {
        //APP传值*******begin*********
        if (checkAndroid()) {
          console.log('----checkAndroid()-=true')
          backToAndroid()
        } else if (checkIOS()) {
          console.log('----checkAndroid()-=true')
          window.webkit.messageHandlers.closeWindow.postMessage({})
          // console.log("对ios传参，调用IOS关闭页面方法,zheshi");
        }
        //APP传值*******end*********
      } else {
        this.$router.go(-1)
      }
    },

    //获取用户权限
    getStaffId() {
      const that = this
      let initData = {}
      initData.staff_id = getStaffId()

      userAuthList(initData).then(function(response) {
        responseUtil.dealResponse(that, response, () => {
          let list = response.data.data.data
          //将对象转为string
          var result = JSON.stringify(list)

          if (result.indexOf('dataService_index') != -1) {
            that.tempAuth0 = true
          }
          if (result.indexOf('concentrateDapartment_index') != -1) {
            that.tempAuth1 = true
          }
          if (result.indexOf('staffDataStatistics_index') != -1) {
            that.tempAuth2 = true
          }
          if (result.indexOf('financeManage_index') != -1) {
            that.tempAuth3 = true
          }
          if (result.indexOf('decorationData_index') != -1) {
            that.tempAuth4 = true
          }
          if (result.indexOf('AfterRentDataStatistics_index') != -1) {
            that.tempAuth5 = true
          }
          if (result.indexOf('clientServeDataStatistics_index') != -1) {
            that.tempAuth6 = true
          }
          if (result.indexOf('performanceReport_index') != -1) {
            that.tempAuth7 = true
          }
          if (result.indexOf('userPortrait_index') != -1) {
            that.tempAuth8 = true
          }
        })
      })
    },
  },
}
</script>

<style scoped>
.statisticsModuleHeader {
  position: fixed;
  width: 100%;
  top: 0px;
  z-index: 2;
  background: linear-gradient(to right, #ffc274, #ff5d3b);
}
.statisticsModuleBody {
  margin-top: 2.9rem;
  z-index: 1;
}
.statisticsModuleTitle {
  width: 100%;
  height: 1.33333rem;
}
.statisticsModuleLeftReturn {
  width: 2rem;
  font-size: 0.37333rem;
  height: 100%;
  float: left;
}
.statisticsModuleLeftReturnImg {
  width: 58%;
  height: 60%;
  margin-top: 10px;
}
.statisticsModuleTitleName {
  color: white;
  font-size: 18px;
  width: 6rem;
  height: 100%;
  float: left;
  line-height: 1.33333rem;
  text-align: center;
}
</style>
