<template>
  <div class="businessModule">
    <!--项目选择模块-->
    <div class="module-small" @click="showProject">
      <span class="project-name">{{
        departmentName != undefined && departmentId != ''
          ? departmentName
          : '部门检索'
      }}</span>
      <img class="drop-down-image" src="../../assets/images/drop-down.png" />
    </div>
    <!--拉下菜单-->
    <van-popup v-model="isShowProjectMenu" position="bottom">
      <div style="background-color: #f7f8fa">
        <div class="choose-company-text">选择公司</div>
        <van-row justify="center" style="background-color: #f7f8fa">
          <div v-for="(item, index) in companyList" :key="index">
            <van-col span="8" offset="4" class="company-col-style"
              ><div
                :class="
                  checkedIndex == index
                    ? 'company-col-checked-style'
                    : 'company-text-style'
                "
                @click="selectCompany(item, index)"
              >
                {{ item.departmentName ? item.departmentName : '部门检索' }}
              </div></van-col
            >
          </div>
        </van-row>
      </div>
      <van-tree-select
        :active-id.sync="activeId"
        :main-active-index.sync="activeIndex"
        :items="items"
        @click-nav="selectArea"
        @click-item="selectDepartment"
      />
      <div class="search">
        <van-button
          style="width: 2rem; color: orangered;border-color: orangered"
          plain
          type="primary"
          @click="resetDepartmentInfo"
          >清空</van-button
        >
        <van-button
          @click="searchDepartmentInfo"
          style="width: 2.8rem;margin-left: 4rem"
          color="linear-gradient(to right, #ff6034, #ee0a24)"
          size="normal"
        >
          筛选
        </van-button>
      </div>
    </van-popup>
    <div class="businessModuleCardTitle">签约量（间）</div>
    <div class="businessModuleHouseCardBody">
      <div class="businessModuleHouseCardBody_signTitle">
        <div
          @click="changeType(4)"
          :class="houseType == 4 ? 'houseCheck' : 'houseNotCheck'"
        >
          合租
        </div>
        <div
          @click="changeType(3)"
          :class="houseType == 3 ? 'houseCheck' : 'houseNotCheck'"
        >
          整租
        </div>
        <div
          @click="changeType(2)"
          :class="houseType == 2 ? 'houseCheck' : 'houseNotCheck'"
        >
          集寓
        </div>
        <div
          @click="changeType(1)"
          :class="houseType == 1 ? 'houseCheck' : 'houseNotCheck'"
        >
          全部
        </div>
      </div>
      <div class="businessModuleHouseCardBody_signBody">
        <div class="businessModuleHouseCardBody_signLeft">
          <div class="businessModuleHouseCardBody_signLeftTitle">收房情况</div>
          <div class="businessModuleHouseCardBody_signLeftBody">
            <div class="businessModuleHouseCardBody_signLeftBody_left">
              <div class="businessModuleHouseCardBody_signLeftBody_leftNum">
                {{ tempCollectSignObj.todaySignNum }}
              </div>
              <div class="businessModuleHouseCardBody_signLeftBody_leftTitle">
                今日
              </div>
            </div>
            <div class="businessModuleHouseCardBody_signLeftBody_line"></div>
            <div class="businessModuleHouseCardBody_signLeftBody_right">
              <div class="businessModuleHouseCardBody_signLeftBody_rightNum">
                <div
                  class="businessModuleHouseCardBody_signLeftBody_rightNum_title"
                >
                  本周
                </div>
                <div
                  class="businessModuleHouseCardBody_signLeftBody_rightNum_Num"
                >
                  {{ tempCollectSignObj.weekSignNum }}
                </div>
              </div>
              <div class="businessModuleHouseCardBody_signLeftBody_rightNum">
                <div
                  class="businessModuleHouseCardBody_signLeftBody_rightNum_title"
                >
                  本月
                </div>
                <div
                  class="businessModuleHouseCardBody_signLeftBody_rightNum_Num"
                >
                  {{ tempCollectSignObj.monthSignNum }}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="businessModuleHouseCardBody_signLine"></div>
        <div class="businessModuleHouseCardBody_signRight">
          <div class="businessModuleHouseCardBody_signLeftTitle">出房情况</div>
          <div class="businessModuleHouseCardBody_signLeftBody">
            <div class="businessModuleHouseCardBody_signLeftBody_left">
              <div class="businessModuleHouseCardBody_signLeftBody_leftNum">
                {{ tempOutSignObj.todaySignNum }}
              </div>
              <div class="businessModuleHouseCardBody_signLeftBody_leftTitle">
                今日
              </div>
            </div>
            <div class="businessModuleHouseCardBody_signLeftBody_line"></div>
            <div class="businessModuleHouseCardBody_signLeftBody_right">
              <div class="businessModuleHouseCardBody_signLeftBody_rightNum">
                <div
                  class="businessModuleHouseCardBody_signLeftBody_rightNum_title"
                >
                  本周
                </div>
                <div
                  class="businessModuleHouseCardBody_signLeftBody_rightNum_Num"
                >
                  {{ tempOutSignObj.weekSignNum }}
                </div>
              </div>
              <div class="businessModuleHouseCardBody_signLeftBody_rightNum">
                <div
                  class="businessModuleHouseCardBody_signLeftBody_rightNum_title"
                >
                  本月
                </div>
                <div
                  class="businessModuleHouseCardBody_signLeftBody_rightNum_Num"
                >
                  {{ tempOutSignObj.monthSignNum }}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>    
    <div class="businessModuleCardTitle">
      房间量（{{ totalHouseNumData }}间）
    </div>
    <div class="businessModuleHouseCardBody">
      <div class="businessModuleHouseCardBody_Style" id="houseNum"></div>
      <div class="businessModuleCardLine"></div>
      <div class="businessModuleHouseCardBody_Style" id="houseVacant"></div>
    </div>
    <div class="businessModuleCardTitle">
      月出房排行榜
      <div class="leftCheckTime" @click="leftArrow(9)"></div>
      <span class="checkTimetitle" @click="checkInDate(10)">{{
        rankEndTime | formatYearMonth
      }}</span>
      <span class="checkTimetitle" style="margin-left: 5px;margin-right: 5px"
        >至</span
      >
      <span class="checkTimetitle" @click="checkInDate(9)">{{
        rankBeginTime | formatYearMonth
      }}</span>
      <div class="rightCheckTime" @click="leftArrow(10)"></div>
    </div>

    <div class="businessModuleHouseCardBody">
      <div
        class="housebarChart"
        id="entryChannels"
        style="width: 97%;height: 340px"
      ></div>
      <div
        v-if="outHouse.rows.length == 0"
        class="housebarChart"
        style="width: 95%;height: 340px; text-align: center; line-height: 340px; font-size: 0.37333rem;"
      >
        暂无数据
      </div>
    </div>

    <div class="businessModuleCardTitle">月收房排行榜</div>
    <div class="businessModuleHouseCardBody">
      <!--      <ve-bar-->
      <!--          v-if="collectHouse.rows.length != 0"-->
      <!--          class="housebarChart"-->
      <!--          style="width: 97%;height: 340px;top: -30px;"-->
      <!--          :data="collectHouse"-->
      <!--          :settings="collectHouseSettings"-->
      <!--      ></ve-bar>-->
      <div
        class="housebarChart"
        id="entryChannels2"
        style="width: 97%;height: 340px"
      ></div>
      <div
        v-if="outHouse.rows.length == 0"
        class="housebarChart"
        style="width: 95%;height: 340px; text-align: center; line-height: 340px; font-size: 0.37333rem;"
      >
        暂无数据
      </div>
    </div>

    <div class="businessModuleCardTitle">
      成交分析（笔）
      <div class="leftCheckTime" @click="leftArrow(1)"></div>
      <span class="checkTimetitle" @click="checkInDate(2)">{{
        financeEndTime | formatYearMonth
      }}</span>
      <span class="checkTimetitle" style="margin-left: 5px;margin-right: 5px"
        >至</span
      >
      <span class="checkTimetitle" @click="checkInDate(1)">{{
        financeBeginTime | formatYearMonth
      }}</span>
      <div class="rightCheckTime" @click="leftArrow(2)"></div>
    </div>
    <div class="businessModuleHouseCardBody">
      <div class="businessModuleHouseCardBody_Style" id="financeType"></div>
      <div class="businessModuleCardLine"></div>
      <div class="businessModuleHouseCardBody_Style" id="financeMarket"></div>
    </div>

    <div class="businessModuleCardTitle">
      签约情况
      <div class="leftCheckTime" @click="leftArrow(3)"></div>
      <span class="checkTimetitle" @click="checkInDate(4)">{{
        signEndTime | formatYearMonth
      }}</span>
      <span class="checkTimetitle" style="margin-left: 5px;margin-right: 5px"
        >至</span
      >
      <span class="checkTimetitle" @click="checkInDate(3)">{{
        signBeginTime | formatYearMonth
      }}</span>
      <div class="rightCheckTime" @click="leftArrow(4)"></div>
    </div>
    <div class="businessModuleHouseCardBody">
      <ve-histogram
        :data="signData"
        :settings="signSettings"
        style="margin-top: 10px"
      ></ve-histogram>
      <div class="businessModuleCardLine"></div>
      <ve-histogram
        :data="renewalData"
        :settings="renewalSettings"
        style="margin-top: 10px"
      ></ve-histogram>
      <div class="businessModuleCardLine"></div>
      <ve-histogram
        :data="breakContractData"
        :settings="breakContractSettings"
        style="margin-top: 10px"
      ></ve-histogram>
    </div>

    <div class="businessModuleCardTitle">
      平均出房周期
      <div class="leftCheckTime" @click="leftArrow(5)"></div>
      <span class="checkTimetitle" @click="checkInDate(6)">{{
        outHouseEndTime | formatYearMonth
      }}</span>
      <span class="checkTimetitle" style="margin-left: 5px;margin-right: 5px"
        >至</span
      >
      <span class="checkTimetitle" @click="checkInDate(5)">{{
        outHouseBeginTime | formatYearMonth
      }}</span>
      <div class="rightCheckTime" @click="leftArrow(6)"></div>
    </div>
    <div class="businessModuleHouseCardBody">
      <div class="businessModuleHouseCardBody_outHouse">
        <div
          class="businessModuleHouseCardBody_outHouseNum"
          style="color: #FF5D3B"
        >
          {{ outHousePeriodObj.totalOutHousePeriodNum }}
        </div>
        <div class="businessModuleHouseCardBody_outHouseText">全部房源</div>
      </div>
      <div class="businessModuleHouseCardBody_outHouse">
        <div class="businessModuleHouseCardBody_outHouseNum">
          {{ outHousePeriodObj.jointOutHousePeriodNum }}
        </div>
        <div class="businessModuleHouseCardBody_outHouseText">合租</div>
      </div>
      <div class="businessModuleHouseCardBody_outHouse">
        <div class="businessModuleHouseCardBody_outHouseNum">
          {{ outHousePeriodObj.wholeOutHousePeriodNum }}
        </div>
        <div class="businessModuleHouseCardBody_outHouseText">整租</div>
      </div>
      <div class="businessModuleHouseCardBody_outHouse">
        <div class="businessModuleHouseCardBody_outHouseNum">
          {{ outHousePeriodObj.setMainOutHousePeriodNum }}
        </div>
        <div class="businessModuleHouseCardBody_outHouseText">集寓</div>
      </div>
      <div style="clear:both"></div>
    </div>

    <div class="businessModuleCardTitle">
      利差（元）
      <div class="leftCheckTime" @click="leftArrow(7)"></div>
      <span class="checkTimetitle" @click="checkInDate(8)">{{
        spreadEndTime | formatYearMonth
      }}</span>
      <span class="checkTimetitle" style="margin-left: 5px;margin-right: 5px"
        >至</span
      >
      <span class="checkTimetitle" @click="checkInDate(7)">{{
        spreadBeginTime | formatYearMonth
      }}</span>
      <div class="rightCheckTime" @click="leftArrow(8)"></div>
    </div>
    <div class="businessModuleHouseCardBody">
      <!--            <div class="businessModuleHouseCardBody_outHouse">-->
      <!--                <div class="businessModuleHouseCardBody_outHouseNum" style="color: #FF5D3B">23255145</div>-->
      <!--                <div class="businessModuleHouseCardBody_outHouseText">全部房源</div>-->
      <!--            </div>-->
      <div class="businessModuleHouseCardBody_outHouse">
        <div class="businessModuleHouseCardBody_outHouseNum">
          {{ interestObj.jointRent }}
        </div>
        <div class="businessModuleHouseCardBody_outHouseText">合租</div>
      </div>
      <div class="businessModuleHouseCardBody_outHouse">
        <div class="businessModuleHouseCardBody_outHouseNum">
          {{ interestObj.wholeRent }}
        </div>
        <div class="businessModuleHouseCardBody_outHouseText">整租</div>
      </div>
      <!--            <div class="businessModuleHouseCardBody_outHouse">-->
      <!--                <div class="businessModuleHouseCardBody_outHouseNum">3543453</div>-->
      <!--                <div class="businessModuleHouseCardBody_outHouseText">集寓</div>-->
      <!--            </div>-->
      <div style="clear:both"></div>
    </div>
    <!--时间选择器-->
    <van-popup v-model="isCheckInDateShow" position="bottom">
      <van-datetime-picker
        v-model="currentDate"
        type="year-month"
        title="选择年月"
        item-height="35px"
        @confirm="checkInDateConfirm"
        @cancel="isCheckInDateShow = false"
      />
    </van-popup>
  </div>
</template>
<script>
import {
  DatetimePicker,
  Popup,
  Sticky,
  Picker,
  Tab,
  Tabs,
  TreeSelect,
  Col,
  Row,
  Button,
} from 'vant'
import {
  houseStateProportion,
  vacanyDaysNumProportion,
  homeForeclosure,
  interestMargin,
  signNum,
  outHousePeriod,
  queryAuthLevelDepartment,
  signCount,
  breakContractNum,
  renewalNum,
  rentTimeProportion,
  businessUserPaymantType,
  totalHouseNum,
} from '../../getData/getData'
import { responseUtil, addMonth, getStaffId } from '../../libs/rongxunUtil'
import { fmoney } from '../../libs/rongxunMoneyUtil'
import util from '../contract/util2'

var echarts = require('echarts')
export default {
  name: 'businessModule',
  components: {
    [Sticky.name]: Sticky,
    [Tab.name]: Tab,
    [Picker.name]: Picker,
    [Col.name]: Col,
    [Row.name]: Row,
    [Tabs.name]: Tabs,
    [Popup.name]: Popup,
    [Button.name]: Button,
    [TreeSelect.name]: TreeSelect,
    [DatetimePicker.name]: DatetimePicker,
  },
  filters: {
    formatDateTime(value) {
      if (null == value || '' == value) return
      let date = new Date(value)
      let y = date.getFullYear()
      let MM = date.getMonth() + 1
      MM = MM < 10 ? '0' + MM : MM
      let d = date.getDate()
      d = d < 10 ? '0' + d : d
      let h = date.getHours()
      h = h < 10 ? '0' + h : h
      let m = date.getMinutes()
      m = m < 10 ? '0' + m : m
      let s = date.getSeconds()
      s = s < 10 ? '0' + s : s
      return y + '-' + MM + '-' + d
    },
    formatYearMonth(value) {
      if (null == value || '' == value) return
      let date = new Date(value)
      let y = date.getFullYear()
      let MM = date.getMonth() + 1
      MM = MM < 10 ? '0' + MM : MM
      let d = date.getDate()
      d = d < 10 ? '0' + d : d
      let h = date.getHours()
      h = h < 10 ? '0' + h : h
      let m = date.getMinutes()
      m = m < 10 ? '0' + m : m
      let s = date.getSeconds()
      s = s < 10 ? '0' + s : s
      return y + '-' + MM
    },
  },
  data() {
    ;(this.outHouseSettings = {
      metrics: ['value'],
      labelMap: {
        value: '',
      },
      dataOrder: {
        label: 'value',
        order: 'desc',
      },
    }),
      (this.collectHouseSettings = {
        metrics: ['value'],
        labelMap: {
          value: '',
        },
        dataOrder: {
          label: 'value',
          order: 'desc',
        },
      }),
      (this.signSettings = {
        showLine: ['签约量折线'],
      }),
      (this.renewalSettings = {
        showLine: ['续签量折线'],
      }),
      (this.breakContractSettings = {
        showLine: ['违约量折线'],
      })
    return {
      checkedIndex: 0,
      totalHouseNumData: 0,
      departmentName: '',
      departmentId: '', //部门id
      functionId: '394',
      companyList: [],
      activeId: -1,
      activeIndex: 0,
      items: [
        {
          text: '浙江',
          children: [
            { text: '杭州', id: 1 },
            { text: '温州', id: 2 },
          ],
        },
        {
          text: '辽宁',
          children: [
            { text: '沈阳', id: 3 },
            { text: '大连', id: 4 },
          ],
        },
        {
          text: '江苏',
          children: [
            { text: '南京', id: 5 },
            { text: '无锡', id: 6 },
          ],
        },
      ],
      isShowProjectMenu: false, //控制下拉菜单显示与否标识
      projectName: '集寓项目名称',
      projectDataInfo: [],
      timeIndex: '',
      currentDate: new Date(),
      isCheckInDateShow: false,
      financeBeginTime: this.formatDate(new Date()),
      financeEndTime: new Date(),
      rankBeginTime: this.formatDate(new Date()),
      rankEndTime: new Date(),
      signBeginTime: addMonth(new Date(), -5),
      signEndTime: new Date(),
      outHouseBeginTime: this.formatDate(new Date()),
      outHouseEndTime: new Date(),
      spreadBeginTime: this.formatDate(new Date()),
      spreadEndTime: new Date(),
      tempOutSignObj: {
        todaySignNum: 0,
        weekSignNum: 0,
        monthSignNum: 0,
      },
      tempCollectSignObj: {
        todaySignNum: 0,
        weekSignNum: 0,
        monthSignNum: 0,
      },
      interestObj: {
        // 利差
        wholeRent: '0',
        jointRent: '0',
      },
      outHousePeriodObj: {
        setMainOutHousePeriodNum: '0',
        jointOutHousePeriodNum: '0',
        wholeOutHousePeriodNum: '0',
        totalOutHousePeriodNum: '0',
      },
      signOutHouseObj: {}, // 签约量出房情况
      signCollectHouseObj: {}, // 签约量收房情况
      houseType: 1, //1 全部 2 集寓 3 整租 4合租
      //月出房
      outHouse: {
        columns: ['staffName', 'value'],
        rows: [
          { staffName: '陈', value: 1391 },
          { staffName: '张', value: 5301 },
          { staffName: '王', value: 2232 },
          { staffName: '李', value: 173 },
          { staffName: '从', value: 392 },
          { staffName: '古纳莉萨', value: 493 },
        ],
      },
      //月收房
      collectHouse: {
        columns: ['staffName', 'value'],
        rows: [
          { staffName: '陈', value: 193 },
          { staffName: '张', value: 530 },
          { staffName: '王', value: 223 },
          { staffName: '李', value: 173 },
          { staffName: '从', value: 372 },
          { staffName: '123123', value: 455 },
        ],
      },
      //签约情况(签约量)
      signData: {
        columns: ['日期', '签约量', '签约量折线'],
        rows: [
          // { '日期': '1/1', '访问用户': 1393, '下单用户': 1093, '下单率': 0.32 },
          // { '日期': '1/2', '访问用户': 3530, '下单用户': 3230, '下单率': 0.26 },
          // { '日期': '1/3', '访问用户': 2923, '下单用户': 2623, '下单率': 0.76 },
          // { '日期': '1/4', '访问用户': 1723, '下单用户': 1423, '下单率': 0.49 },
          // { '日期': '1/5', '访问用户': 3792, '下单用户': 3492, '下单率': 0.323 },
          // { '日期': '1/6', '访问用户': 4593, '下单用户': 4293, '下单率': 0.78 }
        ],
      },
      //签约情况(违约量)
      breakContractData: {
        columns: ['日期', '违约量', '违约量折线'],
        rows: [
          // { '日期': '1/1', '访问用户': 1393, '下单用户': 1093, '下单率': 0.32 },
          // { '日期': '1/2', '访问用户': 3530, '下单用户': 3230, '下单率': 0.26 },
          // { '日期': '1/3', '访问用户': 2923, '下单用户': 2623, '下单率': 0.76 },
          // { '日期': '1/4', '访问用户': 1723, '下单用户': 1423, '下单率': 0.49 },
          // { '日期': '1/5', '访问用户': 3792, '下单用户': 3492, '下单率': 0.323 },
          // { '日期': '1/6', '访问用户': 4593, '下单用户': 4293, '下单率': 0.78 }
        ],
      },
      //签约情况(续签量)
      renewalData: {
        columns: ['日期', '续签量', '续签量折线'],
        rows: [
          // { '日期': '1/1', '访问用户': 1393, '下单用户': 1093, '下单率': 0.32 },
          // { '日期': '1/2', '访问用户': 3530, '下单用户': 3230, '下单率': 0.26 },
          // { '日期': '1/3', '访问用户': 2923, '下单用户': 2623, '下单率': 0.76 },
          // { '日期': '1/4', '访问用户': 1723, '下单用户': 1423, '下单率': 0.49 },
          // { '日期': '1/5', '访问用户': 3792, '下单用户': 3492, '下单率': 0.323 },
          // { '日期': '1/6', '访问用户': 4593, '下单用户': 4293, '下单率': 0.78 }
        ],
      },
      //新的列表接口所需数据
      departmentAuthData: {},
    }
  },
  mounted() {
    //公司初始化
    this.queryAuthLevelDepartment()
  },
  methods: {
    searchDepartmentInfo() {
      //房间量
      this.houseNumData()
      // this.houseNum()
      //空置房屋情况分布
      this.houseVacantData()
      // 利差
      this.interestMarginData()
      //月收房、出房排行榜
      this.homeForeclosureData()
      //今、周、月签约量
      this.signNum()
      // 房间总量
      this.totalHouseNum()
      //平均出房周期
      this.outHousePeriod()
      //租客租期时长占比
      this.rentTimeProportionData()
      //签约情况 （签约量）
      this.getSignCount()
      //签约情况 （违约量）
      this.getBreakContractNum()
      //签约情况 （续签量）
      this.getRenewalNum()
      //租客付款方式占比
      this.getUserPaymantType()
      this.isShowProjectMenu = false
    },
    /*点击箭头，增加或减少一个月*/
    leftArrow(paramType) {
      if (paramType == 1) {
        //成交分析
        let date = new Date(this.financeEndTime)
        this.financeEndTime = this.addDate(date)
        //租客付款方式占比
        this.getUserPaymantType()
        //租客租期时长占比
        this.rentTimeProportionData()
      } else if (paramType == 2) {
        //成交分析
        let date = new Date(this.financeBeginTime)
        this.financeBeginTime = this.reduceDate(date)
        //租客付款方式占比
        this.getUserPaymantType()
        //租客租期时长占比
        this.rentTimeProportionData()
      } else if (paramType == 3) {
        //签约情况  最小时间  3
        let date = new Date(this.signEndTime)
        this.signEndTime = this.addDate(date)
        //签约情况 （签约量）
        this.getSignCount()
        //签约情况 （违约量）
        this.getBreakContractNum()
        //签约情况 （续签量）
        this.getRenewalNum()
      } else if (paramType == 4) {
        //签约情况  最大时间  4
        let date = new Date(this.signBeginTime)
        this.signBeginTime = this.reduceDate(date)
        //签约情况 （签约量）
        this.getSignCount()
        //签约情况 （违约量）
        this.getBreakContractNum()
        //签约情况 （续签量）
        this.getRenewalNum()
      } else if (paramType == 5) {
        //平均出房周期 5
        let date = new Date(this.outHouseEndTime)
        this.outHouseEndTime = this.addDate(date)
        //平均出房周期
        this.outHousePeriod()
      } else if (paramType == 6) {
        //平均出房周期 6
        let date = new Date(this.outHouseBeginTime)
        this.outHouseBeginTime = this.reduceDate(date)
        //平均出房周期
        this.outHousePeriod()
      } else if (paramType == 7) {
        //利差 最小时间  7
        let date = new Date(this.spreadEndTime)
        this.spreadEndTime = this.addDate(date)
        // 利差
        this.interestMarginData()
      } else if (paramType == 8) {
        //利差 最大时间  8
        let date = new Date(this.spreadBeginTime)
        this.spreadBeginTime = this.reduceDate(date)
        // 利差
        this.interestMarginData()
      } else if (paramType == 9) {
        //利差 月收房、出房排行榜 7
        let date = new Date(this.rankEndTime)
        this.rankEndTime = this.addDate(date)
        //月收房、出房排行榜
        this.homeForeclosureData()
      } else if (paramType == 10) {
        //利差 月收房、出房排行榜  8
        debugger
        let date = new Date(this.rankBeginTime)
        this.rankBeginTime = this.reduceDate(date)
        //月收房、出房排行榜
        this.homeForeclosureData()
      }
    },
    //使年月格式的时间减少一个月
    reduceDate(dateTime) {
      if (dateTime.getMonth() + 1 > 1) {
        let year = `${dateTime.getFullYear()}`
        let month = `${dateTime.getMonth() + 1 - 1}`
        if (month < 10) {
          month = `0${dateTime.getMonth() + 1 - 1}`
        }
        return year + '-' + month
      } else {
        let year = `${dateTime.getFullYear() - 1}`
        let month = 12
        return year + '-' + month
      }
    },

    //使年月格式的时间增加一个月
    addDate(dateTime) {
      if (dateTime.getMonth() + 1 > 11) {
        let year = `${dateTime.getFullYear() + 1}`
        let month = `01`
        return year + '-' + month
      } else {
        let year = `${dateTime.getFullYear()}`
        let month = `${dateTime.getMonth() + 1 + 1}`
        if (month < 10) {
          month = `0${dateTime.getMonth() + 1 + 1}`
        }
        return year + '-' + month
      }
    },
    formatDate(dateTime) {
      let year = `${dateTime.getFullYear()}`
      let month = `${dateTime.getMonth() + 1}`
      if (month < 10) {
        month = `0${dateTime.getMonth() + 1}`
      }
      return year + '-' + month
    },
    queryAuthLevelDepartment() {
      debugger
      const that = this
      let initData = {}
      initData.staff_id = getStaffId() //员工id
      initData.function_id = that.functionId //方法模块id
      queryAuthLevelDepartment(initData).then(res =>
        responseUtil.dealResponse(that, res, () => {
          that.departmentAuthData = res.data.data
          let allList = that.departmentAuthData.list
          if (allList != null) {
            that.companyList = allList.filter(item => item.level == 1)
          }
          let departmentId = that.departmentAuthData.roleDepartmentId
          that.departmentId = departmentId
          let item = that.departmentAuthData.list.find(
            item => item.id == departmentId
          )
          that.departmentName = item ? item.departmentName || '' : ''
          that.initOtherDepartment()
          that.searchDepartmentInfo()
        })
      )
    },
    initOtherDepartment() {
      let that = this
      let allList = that.departmentAuthData.list
      let departmentId = that.departmentId
      debugger
      let threeLevelDepartments = allList.filter(item => item.level == 3)
      let fiveLevelDepartments = allList.filter(item => item.level == 5)
      let levelDepartments = threeLevelDepartments
        .filter(item => {
          let levelTop = item.levelTop || ''
          return levelTop.indexOf(departmentId) != -1
        })
        .map(item => {
          let result = {}
          result.children = fiveLevelDepartments
            .filter(child => {
              let levelTop = child.levelTop || ''
              let levelTop1 = child.departmentName
              let levelTop2 = item.departmentName
              debugger
              return levelTop.indexOf(',' + item.id) != -1
            })
            .map(child => {
              let childResult = {}
              childResult.text = child.departmentName
              childResult.id = child.id
              childResult.level = child.level
              return childResult
            })
          result.text = item.departmentName
          result.value = item.id
          result.level = item.level
          return result
        })
      that.items = levelDepartments // 公司列表
    },
    resetDepartmentInfo() {
      let departmentId = this.departmentAuthData.roleDepartmentId
      let item = this.departmentAuthData.list.find(
        item => item.id == departmentId
      )
      this.departmentName = item ? item.departmentName || '' : ''
      this.departmentId = departmentId
      this.isShowProjectMenu = false
      this.searchDepartmentInfo()
    },

    //选择公司，联动下方区、部
    selectCompany(item, index) {
      let that = this
      let roleLevel = this.departmentAuthData.roleLevel || 0
      let roleDepartmentId = this.departmentAuthData.roleDepartmentId || ''
      if (roleDepartmentId != item.id && Number(item.level) < roleLevel) {
        this.isShowProjectMenu = false
        responseUtil.alertErrMsg(that, '权限不足')
        return
      }
      this.checkedIndex = index
      this.departmentId = item.id
      this.activeId = this.departmentId
      this.departmentName = item.departmentName
      this.initOtherDepartment()
    },
    selectArea(value) {
      let item = this.items[value]
      let roleLevel = this.departmentAuthData.roleLevel || 0
      let roleDepartmentId = this.departmentAuthData.roleDepartmentId || ''
      if (roleDepartmentId != item.value && Number(item.level) < roleLevel) {
        this.isShowProjectMenu = false
        responseUtil.alertErrMsg(this, '权限不足')
        return
      }
      this.departmentId = item.value
      this.departmentName = item.text
      this.activeId = this.departmentId
    },
    selectDepartment(data) {
      let roleLevel = this.departmentAuthData.roleLevel || 0
      let roleDepartmentId = this.departmentAuthData.roleDepartmentId || ''
      if (roleDepartmentId != data.value && Number(data.level) < roleLevel) {
        this.isShowProjectMenu = false
        responseUtil.alertErrMsg(this, '权限不足')
        return
      }
      this.departmentId = data.id
      this.departmentName = data.text
    },
    //显示下拉菜单
    showProject() {
      this.isShowProjectMenu = true
    },
    //取消选择
    cancelChangeProject() {
      this.projectName = '集寓项目名称'
      this.projectId = ''
      this.isShowProjectMenu = false
    },
    changeType: function(type) {
      if (type == 1) {
        //收房情况
        this.tempCollectSignObj.todaySignNum = this.signCollectHouseObj.shoufangTotalToday
        this.tempCollectSignObj.weekSignNum = this.signCollectHouseObj.shoufangTotalWeek
        this.tempCollectSignObj.monthSignNum = this.signCollectHouseObj.shoufangTotalMonth
        //出房情况
        this.tempOutSignObj.todaySignNum = this.signOutHouseObj.chufangTotalToday
        this.tempOutSignObj.weekSignNum = this.signOutHouseObj.chufangTotalWeek
        this.tempOutSignObj.monthSignNum = this.signOutHouseObj.chufangTotalMonth
      } else if (type == 2) {
        // 集寓
        //收房情况
        this.tempCollectSignObj.todaySignNum = this.signCollectHouseObj.shoufangJiyuToday
        this.tempCollectSignObj.weekSignNum = this.signCollectHouseObj.shoufangJiyuWeek
        this.tempCollectSignObj.monthSignNum = this.signCollectHouseObj.shoufangJiyuMonth
        //出房情况
        this.tempOutSignObj.todaySignNum = this.signOutHouseObj.chufangJiyuToday
        this.tempOutSignObj.weekSignNum = this.signOutHouseObj.chufangJiyuWeek
        this.tempOutSignObj.monthSignNum = this.signOutHouseObj.chufangJiyuMonth
      } else if (type == 3) {
        // 整租
        //收房情况
        this.tempCollectSignObj.todaySignNum = this.signCollectHouseObj.shoufangZhengzuToday
        this.tempCollectSignObj.weekSignNum = this.signCollectHouseObj.shoufangZhengzuWeek
        this.tempCollectSignObj.monthSignNum = this.signCollectHouseObj.shoufangZhengzuMonth
        //出房情况
        this.tempOutSignObj.todaySignNum = this.signOutHouseObj.chufangZhengzuToday
        this.tempOutSignObj.weekSignNum = this.signOutHouseObj.chufangZhengzuWeek
        this.tempOutSignObj.monthSignNum = this.signOutHouseObj.chufangZhengzuMonth
      } else {
        // 合租
        //收房情况
        this.tempCollectSignObj.todaySignNum = this.signCollectHouseObj.shoufangHezuToday
        this.tempCollectSignObj.weekSignNum = this.signCollectHouseObj.shoufangHezuWeek
        this.tempCollectSignObj.monthSignNum = this.signCollectHouseObj.shoufangHezuMonth
        //出房情况
        this.tempOutSignObj.todaySignNum = this.signOutHouseObj.chufangHezuToday
        this.tempOutSignObj.weekSignNum = this.signOutHouseObj.chufangHezuWeek
        this.tempOutSignObj.monthSignNum = this.signOutHouseObj.chufangHezuMonth
      }

      this.houseType = type
    },

    houseNumData() {
      const that = this
      let initData = {}
      initData.departmentId = this.departmentId //departmentId
      houseStateProportion(initData).then(function(response) {
        responseUtil.dealResponse(that, response, () => {
          let list = response.data.data.list // 房屋状态占比

          if (list == '') {
            list = [{ name: '房屋状态占比', value: 0 }]
          }
          that.houseNum(list)
        })
      })
    },
    houseVacantData() {
      const that = this
      let initData = {}
      initData.departmentId = this.departmentId //departmentId
      vacanyDaysNumProportion(initData).then(function(response) {
        responseUtil.dealResponse(that, response, () => {
          let list = response.data.data.list // 空置天数占比
          that.houseVacant(list)
        })
      })
    },
    //利差
    interestMarginData() {
      const that = this
      let initData = {}
      initData.departmentId = this.departmentId //departmentId
      initData.beginTime = util.dateFormat(that.spreadBeginTime) //开始时间
      initData.endTime = util.getCurrentMonthLast(that.spreadEndTime) //结束时间
      interestMargin(initData).then(function(response) {
        responseUtil.dealResponse(that, response, () => {
          that.interestObj.jointRent = fmoney(response.data.data.jinglichahezu)
          that.interestObj.wholeRent = fmoney(
            response.data.data.jinglichaZhengzu
          )
          // that.houseVacant(list)
        })
      })
    },
    //出房周期
    outHousePeriod() {
      const that = this
      let initData = {}
      initData.departmentId = this.departmentId //departmentId
      initData.beginTime = util.dateFormat(that.outHouseBeginTime) //开始时间
      initData.endTime = util.getCurrentMonthLast(that.outHouseEndTime) //结束时间
      outHousePeriod(initData).then(function(response) {
        responseUtil.dealResponse(that, response, () => {
          that.outHousePeriodObj.wholeOutHousePeriodNum =
            response.data.data.wholeOutHousePeriodNum // 整租
          that.outHousePeriodObj.jointOutHousePeriodNum =
            response.data.data.jointOutHousePeriodNum // 合租
          that.outHousePeriodObj.totalOutHousePeriodNum =
            response.data.data.totalOutHousePeriodNum // 全部
          that.outHousePeriodObj.setMainOutHousePeriodNum =
            response.data.data.setMainOutHousePeriodNum // 集寓
        })
      })
    },
    //房间总量
    totalHouseNum() {
      const that = this
      let initData = {}
      initData.departmentId = this.departmentId //departmentId
      totalHouseNum(initData).then(function(response) {
        responseUtil.dealResponse(that, response, () => {
          that.totalHouseNumData = response.data.data.list[0].num
        })
      })
    },
    //签约量
    signNum() {
      const that = this
      let initData = {}
      initData.departmentId = this.departmentId //departmentId
      signNum(initData).then(function(response) {
        responseUtil.dealResponse(that, response, () => {
          that.signOutHouseObj = response.data.data.chufang // 出房签约量
          that.signCollectHouseObj = response.data.data.shoufang // 收房签约量?
          that.changeType(1)
        })
      })
    },
    //租客租期时长占比
    rentTimeProportionData() {
      const that = this
      let initData = {}
      initData.departmentId = this.departmentId //departmentId
      initData.beginTime = util.dateFormat(that.financeBeginTime) //开始时间
      initData.endTime = util.getCurrentMonthLast(that.financeEndTime) //结束时间
      debugger
      rentTimeProportion(initData).then(function(response) {
        responseUtil.dealResponse(that, response, () => {
          let list = response.data.data.list // 租客租期时长
          that.financeMarket(list)
        })
      })
    },

    //签约情况 （签约量）
    getSignCount() {
      const that = this
      let initData = {}

      initData.departmentId = that.departmentId //部门
      initData.beginTime = util.dateFormat(that.signBeginTime) //开始时间
      initData.endTime = util.getCurrentMonthLast(that.signEndTime) //结束时间
      signCount(initData).then(function(response) {
        responseUtil.dealResponse(that, response, () => {
          that.signData.rows = response.data.data.list
          //   { '日期':(response.data.data.beginDate).substring(2,7), '签约量': response.data.data.count[0].begin, '签约量折线':response.data.data.count[0].begin},
          //   { '日期':(response.data.data.oneDate).substring(2,7), '签约量': response.data.data.count[0].one, '签约量折线':response.data.data.count[0].one},
          //   { '日期':(response.data.data.twoDate).substring(2,7), '签约量': response.data.data.count[0].two, '签约量折线':response.data.data.count[0].two},
          //   { '日期':(response.data.data.threeDate).substring(2,7), '签约量': response.data.data.count[0].three, '签约量折线':response.data.data.count[0].three},
          //   { '日期':(response.data.data.fourDate).substring(2,7), '签约量': response.data.data.count[0].four, '签约量折线':response.data.data.count[0].four},
          //   { '日期':(response.data.data.endDate).substring(2,7), '签约量': response.data.data.count[0].end, '签约量折线':response.data.data.count[0].end},
          //
          // ]
        })
      })
    },

    //签约情况 （违约量）
    getBreakContractNum() {
      const that = this
      let initData = {}
      initData.departmentId = that.departmentId //部门
      initData.beginTime = util.dateFormat(that.signBeginTime) //开始时间
      initData.endTime = util.getCurrentMonthLast(that.signEndTime) //结束时间
      breakContractNum(initData).then(function(response) {
        responseUtil.dealResponse(that, response, () => {
          that.breakContractData.rows = response.data.data.list
          //   { '日期':(response.data.data.beginDate).substring(2,7), '违约量': response.data.data.begin.breakContractNum, '违约量折线':response.data.data.begin.breakContractNum},
          //   { '日期':(response.data.data.oneDate).substring(2,7), '违约量': response.data.data.one.breakContractNum, '违约量折线':response.data.data.one.breakContractNum},
          //   { '日期':(response.data.data.twoDate).substring(2,7), '违约量': response.data.data.two.breakContractNum, '违约量折线':response.data.data.two.breakContractNum},
          //   { '日期':(response.data.data.threeDate).substring(2,7), '违约量': response.data.data.three.breakContractNum, '违约量折线':response.data.data.three.breakContractNum},
          //   { '日期':(response.data.data.fourDate).substring(2,7), '违约量': response.data.data.four.breakContractNum, '违约量折线':response.data.data.four.breakContractNum},
          //   { '日期':(response.data.data.endDate).substring(2,7), '违约量': response.data.data.end.breakContractNum, '违约量折线':response.data.data.end.breakContractNum},
          //
          // ]
        })
      })
    },
    //签约情况 （续签量）
    getRenewalNum() {
      const that = this
      let initData = {}
      initData.departmentId = that.departmentId //部门
      initData.beginTime = util.dateFormat(that.signBeginTime) //开始时间
      initData.endTime = util.getCurrentMonthLast(that.signEndTime) //结束时间
      renewalNum(initData).then(function(response) {
        responseUtil.dealResponse(that, response, () => {
          //console.log(response.data.data)
          that.renewalData.rows = response.data.data.list
          // let monthNums = response.data.data.num;
          // let data = response.data.data;
          // let tempName;
          // let tempNum;
          // debugger
          // for(let i = 0 ; i < monthNums; i ++){
          //   tempNum = 'strDate' + i;
          //   tempName = 'Date' + i + '.renewSignNum';
          //   let obj = { '日期':(data.tempNum).substring(2,7), '续签量': data.tempName, '续签量折线':data.tempName};
          //   that.renewalData.rows.push(obj)
          // }

          // that.renewalData.rows = [
          //   { '日期':(response.data.data.beginDate).substring(2,7), '续签量': response.data.data.begin.renewSignNum, '续签量折线':response.data.data.begin.renewSignNum},
          //   { '日期':(response.data.data.oneDate).substring(2,7), '续签量': response.data.data.one.renewSignNum, '续签量折线':response.data.data.one.renewSignNum},
          //   { '日期':(response.data.data.twoDate).substring(2,7), '续签量': response.data.data.two.renewSignNum, '续签量折线':response.data.data.two.renewSignNum},
          //   { '日期':(response.data.data.threeDate).substring(2,7), '续签量': response.data.data.three.renewSignNum, '续签量折线':response.data.data.three.renewSignNum},
          //   { '日期':(response.data.data.fourDate).substring(2,7), '续签量': response.data.data.four.renewSignNum, '续签量折线':response.data.data.four.renewSignNum},
          //   { '日期':(response.data.data.endDate).substring(2,7), '续签量': response.data.data.end.renewSignNum, '续签量折线':response.data.data.end.renewSignNum},
          //
          // ]
        })
      })
    },
    //租客付款方式占比
    getUserPaymantType() {
      const that = this
      let initData = {}

      initData.departmentId = that.departmentId //开始时间
      initData.beginTime = util.dateFormat(that.financeBeginTime) //开始时间
      initData.endTime = util.getCurrentMonthLast(that.financeEndTime) //结束时间
      businessUserPaymantType(initData).then(function(response) {
        responseUtil.dealResponse(that, response, () => {
          let list = response.data.data.list
          //如果空
          if (list.length == 0) {
            list = [{ name: '付款方式', payType_id: 0, value: 0 }]
          }

          that.financeType(list)
        })
      })
    },

    //月收房、出房排行榜
    homeForeclosureData() {
      const that = this
      let initData = {}
      initData.departmentId = this.departmentId //departmentId
      initData.beginTime = util.dateFormat(this.rankBeginTime) //开始时间
      initData.endTime = util.getCurrentMonthLast(this.rankEndTime) //结束时间
      debugger
      homeForeclosure(initData).then(function(response) {
        responseUtil.dealResponse(that, response, () => {
          if (response.data.data.chufangpaiming.length == 0) {
            that.outHouse.rows = []
          } else {
            debugger
            let tempList1 = response.data.data.chufangpaiming // 出房排名
            let staffList1 = []
            let valueList1 = []
            for (let i = tempList1.length; i > 0; i--) {
              staffList1.push(tempList1[i - 1].staffName)
              valueList1.push(tempList1[i - 1].value)
            }

            that.entryChannels1(staffList1, valueList1)
          }
          if (response.data.data.shoufangpaiming.length == 0) {
            that.collectHouse.rows = []
          } else {
            let tempList2 = response.data.data.shoufangpaiming // 出房排名
            let staffList2 = []
            let valueList2 = []
            debugger
            for (let i = tempList2.length; i > 0; i--) {
              staffList2.push(tempList2[i - 1].staffName)
              valueList2.push(tempList2[i - 1].value)
            }
            that.entryChannels2(staffList2, valueList2)
          }
        })
      })
    },
    entryChannels2(pathList1, b) {
      let myChart = echarts.init(document.getElementById('entryChannels2'))
      // 绘制图表
      myChart.setOption({
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'shadow',
          },
        },
        grid: {
          left: '3%',
          right: '4%',
          bottom: '3%',
          containLabel: true,
        },
        color: ['#19d4ae'],
        xAxis: {
          type: 'value',
          boundaryGap: [0, 0.01],
        },
        yAxis: {
          type: 'category',
          // data: ['巴西', '印尼', '美国', '印度', '中国', '世界人口(万)']
          data: pathList1,
        },
        series: [
          {
            type: 'bar',
            // data: [18203, 23489, 29034, 104970, 131744, 630230]
            data: b,
          },
        ],
      })
    },
    entryChannels1(pathList1, b) {
      let myChart = echarts.init(document.getElementById('entryChannels'))
      // 绘制图表
      myChart.setOption({
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'shadow',
          },
        },
        grid: {
          left: '3%',
          right: '4%',
          bottom: '3%',
          containLabel: true,
        },
        color: ['#19d4ae'],
        xAxis: {
          type: 'value',
          boundaryGap: [0, 0.01],
        },
        yAxis: {
          type: 'category',
          // data: ['巴西', '印尼', '美国', '印度', '中国', '世界人口(万)']
          data: pathList1,
        },
        series: [
          {
            type: 'bar',
            // data: [18203, 23489, 29034, 104970, 131744, 630230]
            data: b,
          },
        ],
      })
    },
    //房间量
    houseNum(list) {
      let myChart = echarts.init(document.getElementById('houseNum'))
      // 绘制图表
      myChart.setOption({
        color: [
          '#c23531',
          '#2f4554',
          '#61a0a8',
          '#d48265',
          '#91c7ae',
          '#749f83',
          '#ca8622',
          '#bda29a',
          '#6e7074',
          '#546570',
          '#c4ccd3',
        ],
        title: {
          left: 170,
          top: 45,
          text: '房屋状态占比',
          textStyle: {
            fontSize: 13,
          },
        },
        tooltip: {
          trigger: 'item',
          formatter: '{a} <br/>{b}: {c} ({d}%)',
        },
        legend: {
          orient: 'vertical',
          left: 170,
          top: 75,
          formatter: function(name) {
            var totalNum = 0
            for (var i = 0; i < list.length; i++) {
              totalNum += Number(list[i].value)
            }
            var res = ''
            for (var i = 0; i < list.length; i++) {
              if (list[i].name == name) {
                res = list[i].value
              }
            }
            if (totalNum == 0 || res == 0) {
              return name + '  (0 / 0%)'
            }
            return (
              name +
              '  (' +
              res +
              ' / ' +
              Number((res / totalNum) * 100).toFixed(2) +
              '%)'
            )
          },

          // data: ['可租', '空置', '预到期', '已到期', '配置','预定','已租','不可租','无效'],
          textStyle: {
            color: '#d8d8d8',
          },
          icon: 'circle',
          selectedMode: true, //空置是否可以点击取消
        },

        series: [
          {
            name: '房间量（间）',
            type: 'pie',
            radius: ['35%', '50%'],
            center: ['25%', '50%'],
            avoidLabelOverlap: false,
            label: {
              show: false,
              position: 'center',
            },
            emphasis: {
              label: {
                show: true,
                fontSize: '15',
                fontWeight: 'bold',
              },
            },
            labelLine: {
              show: false,
            },
            // data: [
            //     {value: 335, name: '空置'},
            //     {value: 310, name: '转租'},
            //     {value: 234, name: '申退'},
            //     {value: 135, name: '预到期'},
            //     {value: 1548, name: '已到期'}
            // ]
            data: list,
          },
        ],
      })
    },
    //置房屋情况分布
    houseVacant(list) {
      let myChart = echarts.init(document.getElementById('houseVacant'))
      // 绘制图表
      myChart.setOption({
        color: [
          '#c23531',
          '#2f4554',
          '#61a0a8',
          '#d48265',
          '#91c7ae',
          '#749f83',
          '#ca8622',
          '#bda29a',
          '#6e7074',
          '#546570',
          '#c4ccd3',
        ],
        title: {
          left: 170,
          top: 45,
          text: '空置房屋情况',
          textStyle: {
            fontSize: 13,
          },
        },
        tooltip: {
          trigger: 'item',
          formatter: '{a} <br/>{b}: {c} ({d}%)',
        },
        legend: {
          orient: 'vertical',
          left: 170,
          top: 75,
          // data: ['7天以内', '7-15天', '15-30天', '30-50天', '50天以上'],
          formatter: function(name) {
            var totalNum = 0
            for (var i = 0; i < list.length; i++) {
              totalNum += Number(list[i].value)
            }
            var res = ''
            for (var i = 0; i < list.length; i++) {
              if (list[i].name == name) {
                res = list[i].value
              }
            }
            if (totalNum == 0 || res == 0) {
              return name + '  (0 / 0%)'
            }
            // debugger
            return (
              name +
              '  (' +
              res +
              ' / ' +
              Number((res / totalNum) * 100).toFixed(2) +
              '%)'
            )
          },
          textStyle: {
            color: '#d8d8d8',
          },
          icon: 'circle',
          selectedMode: false, //空置是否可以点击取消
        },

        series: [
          {
            name: '空置房屋情况',
            type: 'pie',
            radius: ['35%', '50%'],
            center: ['25%', '50%'],
            avoidLabelOverlap: false,
            label: {
              show: false,
              position: 'center',
            },
            emphasis: {
              label: {
                show: true,
                fontSize: '15',
                fontWeight: 'bold',
              },
            },
            labelLine: {
              show: false,
            },
            // data: [
            //     {value: 335, name: '7天以内'},
            //     {value: 310, name: '8-15天'},
            //     {value: 234, name: '15-30天'},
            //     {value: 135, name: '30-50天'},
            //     {value: 1548, name: '超过50天'}
            // ]
            data: list,
          },
        ],
      })
    },
    //租客付款方式占比
    financeType(list) {
      let myChart = echarts.init(document.getElementById('financeType'))
      // 绘制图表
      myChart.setOption({
        color: [
          '#19d4ae',
          '#5ab1ef',
          '#fa6e86',
          '#ffb980',
          '#0067a6',
          '#c4b4e4',
          '#d87a80',
          '#9cbbff',
          '#d9d0c7',
          '#87a997',
          '#d49ea2',
          '#5b4947',
          '#7ba3a8',
        ],
        title: {
          left: 155,
          top: 40,
          text: '租客付款方式占比',
          textStyle: {
            fontSize: 13,
          },
        },
        tooltip: {
          trigger: 'item',
          formatter: '{a} <br/>{b} : {c} ({d}%)',
        },
        legend: {
          orient: 'vertical',
          left: 155,
          top: 70,
          formatter: function(name) {
            var totalNum = 0
            for (var i = 0; i < list.length; i++) {
              totalNum += Number(list[i].value)
            }
            var res = ''
            for (var i = 0; i < list.length; i++) {
              if (list[i].name == name) {
                res = list[i].value
              }
            }
            if (totalNum == 0 || res == 0) {
              return name + '  (0 / 0%)'
            }
            // debugger
            return (
              name +
              '  (' +
              res +
              ' / ' +
              Number((res / totalNum) * 100).toFixed(2) +
              '%)'
            )
          },
          //data: ['微信', '支付宝', '线下'],
          textStyle: {
            color: '#d8d8d8',
          },
          icon: 'circle',
        },
        series: [
          {
            name: '付款方式占比',
            type: 'pie',
            radius: '45%',
            center: ['25%', '50%'],
            label: {
              show: false,
            },
            // data: [
            //     {value: 335, name: '微信'},
            //     {value: 310, name: '支付宝'},
            //     {value: 234, name: '线下'},
            // ]
            data: list,
          },
        ],
      })
    },
    //租客租期市场占比（月）
    financeMarket(list) {
      let myChart = echarts.init(document.getElementById('financeMarket'))
      // 绘制图表
      myChart.setOption({
        color: [
          '#19d4ae',
          '#5ab1ef',
          '#fa6e86',
          '#ffb980',
          '#0067a6',
          '#c4b4e4',
          '#d87a80',
          '#9cbbff',
          '#d9d0c7',
          '#87a997',
          '#d49ea2',
          '#5b4947',
          '#7ba3a8',
        ],
        title: {
          left: 155,
          top: 40,
          text: '租客租期时长占比',
          textStyle: {
            fontSize: 13,
          },
        },
        tooltip: {
          trigger: 'item',
          formatter: '{a} <br/>{b} : {c} ({d}%)',
        },
        legend: {
          orient: 'vertical',
          left: 155,
          top: 70,
          formatter: function(name) {
            var totalNum = 0
            for (var i = 0; i < list.length; i++) {
              totalNum += Number(list[i].value)
            }
            var res = ''
            for (var i = 0; i < list.length; i++) {
              if (list[i].name == name) {
                res = list[i].value
              }
            }
            // debugger
            if (totalNum == 0 || res == 0) {
              return name + '  (0 / 0%)'
            }
            return (
              name +
              '  (' +
              res +
              ' / ' +
              Number((res / totalNum) * 100).toFixed(2) +
              '%)'
            )
          },
          // data: ['房租', '房租1', '房租2', '房租3'],
          textStyle: {
            color: '#d8d8d8',
          },
          icon: 'circle',
        },
        series: [
          {
            name: '租期时长占比',
            type: 'pie',
            radius: '45%',
            center: ['25%', '50%'],
            label: {
              show: false,
            },
            // data: [
            //     {value: 335, name: '房租'},
            //     {value: 310, name: '房租1'},
            //     {value: 234, name: '房租2'},
            //     {value: 135, name: '房租3'},
            // ]
            data: list,
          },
        ],
      })
    },
    //显示时间弹层
    checkInDate(i) {
      this.isCheckInDateShow = true
      this.timeIndex = i
    },
    checkInDateConfirm() {
      switch (this.timeIndex) {
        case 1: //财务管理 开始时间
          this.financeBeginTime = this.currentDate
          //租客付款方式占比
          this.getUserPaymantType()
          //租客租期时长占比
          this.rentTimeProportionData()
          break
        case 2: //财务管理 结束时间
          this.financeEndTime = this.currentDate
          //租客付款方式占比
          this.getUserPaymantType()
          //租客租期时长占比
          this.rentTimeProportionData()
          break
        case 3: //签约情况 开始时间
          this.signBeginTime = this.currentDate
          //签约情况 （签约量）
          this.getSignCount()
          //签约情况 （违约量）
          this.getBreakContractNum()
          //签约情况 （续签量）
          this.getRenewalNum()
          break
        case 4: //签约情况 结束时间
          this.signEndTime = this.currentDate
          //签约情况 （签约量）
          this.getSignCount()
          //签约情况 （违约量）
          this.getBreakContractNum()
          //签约情况 （续签量）
          this.getRenewalNum()
          break
        case 5: //平均出房周期 开始时间
          this.outHouseBeginTime = this.currentDate
          this.outHousePeriod()
          break
        case 6: //平均出房周期 结束时间
          this.outHouseEndTime = this.currentDate
          this.outHousePeriod()
          break
        case 7: //利差 开始时间
          this.spreadBeginTime = this.currentDate
          this.interestMarginData()
          break
        case 8: //利差 结束时间
          this.spreadEndTime = this.currentDate
          this.interestMarginData()
          break
        case 9: //月出房、收房 开始时间
          this.rankBeginTime = this.currentDate
          this.homeForeclosureData()
          break
        case 10: //月出房、收房 开始时间
          this.rankEndTime = this.currentDate
          this.homeForeclosureData()
          break
      }
      this.isCheckInDateShow = false
    },
  },
}
</script>
<style scoped>
/*项目选择模块*/
.module-small {
  width: 92%;
  height: 51px;
  margin: 0 auto;
  margin-top: 20px;
  background-color: white;
  border-radius: 8px;
  line-height: 51px;
}
/*项目名称样式*/
.project-name {
  font-size: 15px;
  color: #222222;
  margin-left: 20px;
  font-weight: bold;
}
/*下拉菜单右侧图标样式*/
.drop-down-image {
  float: right;
  margin-right: 0.5rem;
  margin-top: 25px;
  /*padding-bottom: 4px;*/
}
.choose-company-text {
  font-size: 0.4rem;
  font-weight: 700;
  color: black;
  padding: 10px 0 0 5px;
  font-size: 20px;
}
/*公司字体样式 未选中*/
.company-col-style {
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
}
/*公司字体样式 被选中*/
.company-col-checked-style {
  width: 2rem;
  text-align: center;
  background-color: #d3d3d3;
  border-radius: 0.1rem;
  font-size: 17px;
}
.search {
  margin: 15px 10px;
}
/*公司字体样式*/
.company-text-style {
  width: 2rem;
  text-align: center;
  /*background-color:#f7f8fa;*/
  border-radius: 0.1rem;
  font-size: 17px;
}
.businessModuleCardTitle {
  color: #ff5d3b;
  font-size: 0.37333rem;
  text-align: left;
  width: 90%;
  height: 0.6rem;
  line-height: 0.6rem;
  margin-left: 5%;
  padding-top: 0.4rem;
  margin-bottom: 10px;
  z-index: 8888;
  font-weight: bolder;
}
.businessModuleHouseCardBody {
  border-radius: 8px;
  background-color: #ffffff;
  width: 90%;
  min-height: 3rem;
  margin-left: 5%;
  overflow: hidden;
}
.businessModuleHouseCardBody_Style {
  width: 345px;
  height: 250px;
}
.businessModuleCardLine {
  border-bottom: #ff5d3b dashed 0.05333rem;
  width: 100%;
}
.houseCheck {
  width: 55px;
  height: 20px;
  line-height: 20px;
  border-radius: 10px;
  background-color: #ff5d3b;
  font-size: 12px;
  text-align: center;
  color: #ffffff;
  float: right;
  margin: 0.2rem 0.3rem 0rem 0.1rem;
}
.houseNotCheck {
  width: 55px;
  height: 20px;
  line-height: 20px;
  border-radius: 10px;
  background-color: #d8d8d8;
  font-size: 12px;
  text-align: center;
  color: #ffffff;
  float: right;
  margin: 0.2rem 0.3rem 0rem 0.1rem;
}
.businessModuleHouseCardBody_signTitle {
  width: 100%;
  height: 1rem;
}
.businessModuleHouseCardBody_signBody {
  width: 100%;
  height: 3.5rem;
}
.businessModuleHouseCardBody_signLeft {
  width: 48%;
  height: 3rem;
  float: left;
}
.businessModuleHouseCardBody_signLine {
  width: 1%;
  height: 2.7rem;
  margin-top: 0.3rem;
  opacity: 0.32;
  border-right: #ff5d3b dashed 1px;
  float: left;
}
.businessModuleHouseCardBody_signRight {
  width: 48%;
  height: 3rem;
  float: right;
}
.businessModuleHouseCardBody_signLeftTitle {
  width: 100%;
  height: 1rem;
  font-size: 14px;
  color: #222222;
  text-align: center;
  line-height: 1rem;
}
.businessModuleHouseCardBody_signLeftBody {
  width: 100%;
  height: 2rem;
}
.businessModuleHouseCardBody_signLeftBody_left {
  width: 42%;
  height: 100%;
  float: left;
}
.businessModuleHouseCardBody_signLeftBody_leftNum {
  width: 100%;
  height: 50%;
  line-height: 1rem;
  font-size: 25px;
  text-align: center;
  color: #ff5d3b;
}
.businessModuleHouseCardBody_signLeftBody_leftTitle {
  width: 100%;
  height: 50%;
  line-height: 1rem;
  color: #999999;
  font-size: 12px;
  text-align: center;
}
.businessModuleHouseCardBody_signLeftBody_line {
  width: 1%;
  height: 100%;
  opacity: 0.32;
  border-right: #ff5d3b dashed 1px;
  float: left;
}
.businessModuleHouseCardBody_signLeftBody_right {
  width: 53%;
  height: 100%;
  float: left;
}
.businessModuleHouseCardBody_signLeftBody_rightNum {
  width: 100%;
  height: 50%;
  font-size: 14px;
  text-align: center;
  color: #220000;
}
.businessModuleHouseCardBody_signLeftBody_rightNum_title {
  width: 45%;
  height: 100%;
  text-align: right;
  font-size: 14px;
  font-weight: 600;
  float: left;
  line-height: 1rem;
}
.businessModuleHouseCardBody_signLeftBody_rightNum_Num {
  margin-left: 5%;
  width: 50%;
  font-size: 16px;
  font-weight: 600;
  float: left;
  text-align: left;
  line-height: 1rem;
}
.businessModuleHouseCardBody_outHouse {
  width: 50%;
  height: 99px;
  float: left;
}
.businessModuleHouseCardBody_outHouseNum {
  font-size: 20px;
  text-align: center;
  width: 100%;
  height: 50%;
  line-height: 2rem;
  color: #222222;
}
.businessModuleHouseCardBody_outHouseText {
  font-size: 12px;
  text-align: center;
  width: 100%;
  height: 40%;
  line-height: 0.8rem;
  color: #999999;
}
.housebarChart {
  margin-left: 2%;
  z-index: 0;
}
.leftCheckTime {
  border-width: 5px;
  border-style: solid;
  border-color: transparent transparent transparent black;
  float: right;
  margin-top: 6px;
  margin-left: 5px;
}
.rightCheckTime {
  border-width: 5px;
  border-style: solid;
  border-color: transparent black transparent transparent;
  float: right;
  margin-top: 6px;
  margin-right: 5px;
}
.checkTimetitle {
  color: black;
  font-size: 14px;
  font-weight: 600;
  padding: 0 1px;
  float: right;
}
</style>
