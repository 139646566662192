<template>
  <div class="financialModule">
    <div class="module-small" @click="showProject">
      <span class="project-name">{{
        departmentName != undefined && departmentId != ''
          ? departmentName
          : '部门检索'
      }}</span>
      <img class="drop-down-image" src="../../assets/images/drop-down.png" />
    </div>
    <!--拉下菜单-->
    <van-popup v-model="isShowProjectMenu" position="bottom">
      <div style="background-color: #f7f8fa">
        <div class="choose-company-text">选择公司</div>
        <van-row justify="center" style="background-color: #f7f8fa">
          <div v-for="(item, index) in companyList" :key="index">
            <van-col span="8" offset="4" class="company-col-style"
              ><div
                :class="
                  checkedIndex == index
                    ? 'company-col-checked-style'
                    : 'company-text-style'
                "
                @click="selectCompany(item, index)"
              >
                {{ item.departmentName ? item.departmentName : '部门检索' }}
              </div></van-col
            >
          </div>
        </van-row>
      </div>
      <van-tree-select
        :active-id.sync="activeId"
        :main-active-index.sync="activeIndex"
        :items="items"
        @click-nav="selectArea"
        @click-item="selectDepartment"
      />
      <div class="search">
        <van-button
          style="width: 2rem; color: orangered;border-color: orangered"
          plain
          type="primary"
          @click="resetDepartmentInfo"
          >清空</van-button
        >
        <van-button
          @click="searchDepartmentInfo"
          style="width: 2.8rem;margin-left: 4rem"
          color="linear-gradient(to right, #ff6034, #ee0a24)"
          size="normal"
        >
          筛选
        </van-button>
      </div>
    </van-popup>
    <!-- 房屋账单(元)-->
    <div class="title">
      <!--项目选择模块-->

      <span class="redtitle">房屋账单(元)</span>
      <div class="left-triangle" @click="leftArrow(1)"></div>
      <span class="blacktitle" @click="checkInDate(1)">{{
        houseTimeBegin | formatYearMonth
      }}</span>
      <!-- <pre class="blacktitle"> 至 </pre>
      <span class="blacktitle" @click="checkInDate(2)">{{houseTimeEnd | formatYearMonth}}</span> -->
      <div class="right-triangle" @click="leftArrow(2)"></div>
    </div>
    <div style="position: relative">
      <div
        class="button"
        style="right: 95px;"
        :class="{ 'button-no': !shouldOrActual }"
        @click="pressButton(1), getHousesBill()"
      >
        应收/支
      </div>
      <div
        class="button"
        style="right: 25px;"
        :class="{ 'button-no': shouldOrActual }"
        @click="pressButton(2), getHousesBill()"
      >
        实收/支
      </div>
    </div>
    <div class="chart-content" id="houseBill"></div>

    <div class="red-dividingLine"></div>
    <!-- <div class="chart-content" id="houseBillPie"></div> -->
    <!--财务管理(笔)-->
    <div class="title">
      <span class="redtitle">财务管理(笔)</span>
      <div class="left-triangle" @click="leftArrow(3)"></div>
      <span class="blacktitle" @click="checkInDate(3)">{{
        financeTimeBegin | formatYearMonth
      }}</span>
      <pre class="blacktitle">至</pre>
      <span class="blacktitle" @click="checkInDate(4)">{{
        financeTimeEnd | formatYearMonth
      }}</span>
      <div class="right-triangle" @click="leftArrow(4)"></div>
    </div>
    <div class="chart-content1" id="financialManagementIn"></div>
    <div class="red-dividingLine"></div>
    <div class="chart-content1" id="financialManagementOut"></div>
    <!-- <div class="title">
        <span class="redtitle">收款类型占比</span>
        <div class="left-triangle"></div>
        <span class="blacktitle" @click="checkInDate(5)">{{time5 | formatYearMonth}}</span>
        <pre class="blacktitle"> 至 </pre>
        <span class="blacktitle" @click="checkInDate(6)">{{time6 | formatYearMonth}}</span>
        <div class="right-triangle"></div>
    </div>
    <div class="chart-content" id="collectionType"></div> -->
    <div class="title">
      <span class="redtitle">逾期（租金）</span>
    </div>
    <div class="lattices">
      <div class="lattice">
        <div>{{ NumberOfArticles }}</div>
        <span>逾期笔数</span>
      </div>
      <div class="lattice">
        <div>{{ AmountOfMoney }}</div>
        <span>逾期金额</span>
      </div>
    </div>
    <div class="title">
      <span class="redtitle">日收支情况(元)</span>
      <div class="left-triangle" @click="leftArrow(5)"></div>
      <span class="blacktitle" @click="checkInDate(7)">{{
        time7 | formatDateTime
      }}</span>
      <div class="right-triangle" @click="leftArrow(6)"></div>
    </div>
    <div class="chart-content" id="dailyInOut" style="height: 100px"></div>
    <div class="title">
      <span class="redtitle">租后费用(元)</span>
      <div class="left-triangle" @click="leftArrow(7)"></div>
      <span class="blacktitle" @click="checkInDate(8)">{{
        time8 | formatYearMonth
      }}</span>
      <pre class="blacktitle">至</pre>
      <span class="blacktitle" @click="checkInDate(11)">{{
        time11 | formatYearMonth
      }}</span>
      <div class="right-triangle" @click="leftArrow(8)"></div>
    </div>
    <div class="lattices" id="afterRent">
      <div class="lattice">
        <div>{{ decorationFee }}</div>
        <span>装修费</span>
      </div>
      <div class="lattice">
        <div>{{ maintenanceFee }}</div>
        <span>维修费</span>
      </div>
      <div class="lattice">
        <div>{{ distributionFee }}</div>
        <span>配货费</span>
      </div>
      <div class="lattice">
        <div>{{ cleaningfee }}</div>
        <span>保洁费</span>
      </div>
    </div>
    <div class="title">
      <span class="redtitle">装修回款(元)</span>
      <!--            <div class="left-triangle" @click="leftArrow(9)"></div>-->
      <!--            <span class="blacktitle" @click="checkInDate(9)">{{time9 | formatYearMonth}}</span>-->
      <!--            <pre class="blacktitle">至</pre>-->
      <!--            <span class="blacktitle" @click="checkInDate(10)">{{time10 | formatYearMonth}}</span>-->
      <!--            <div class="right-triangle" @click="leftArrow(10)"></div>-->
    </div>
    <div style="position: relative">
      <div
        class="button"
        style="right: 95px;"
        :class="{ 'button-no': !shareOrNot }"
        @click="pressButton(3), getRenovationFund()"
      >
        合租
      </div>
      <div
        class="button"
        style="right: 25px;"
        :class="{ 'button-no': shareOrNot }"
        @click="pressButton(4), getRenovationFund()"
      >
        整租
      </div>
    </div>
    <div class="chart-content" id="decoration" style="height: 100px"></div>
    <van-popup v-model="isCheckInDateShow" position="bottom">
      <van-datetime-picker
        v-model="currentDate"
        :type="timeIndex == 7 ? 'date' : 'year-month'"
        :title="timeIndex == 7 ? '选择年月日' : '选择年月'"
        item-height="35px"
        @confirm="checkInDateConfirm"
        @cancel="isCheckInDateShow = false"
      />
    </van-popup>
  </div>
</template>

<script>
import { responseUtil, getStaffId } from '@/libs/rongxunUtil'
import {
  DatetimePicker,
  Popup,
  Sticky,
  Tab,
  Tabs,
  Picker,
  TreeSelect,
  Col,
  Row,
  Button,
} from 'vant'
import {
  housesBill, //房屋账单
  renovationFund, //装修回款
  receivablePay, //应收，应支
  receivablePayDay, //日应收，应支
  afterRent,
  queryAuthLevelDepartment,
  TotalMoney, //租后费用
} from '../../getData/getData'
import util from '../contract/util2'
import { addMonth, money } from '../../libs/rongxunUtil'
import { fmoney } from '../../libs/rongxunMoneyUtil'
var echarts = require('echarts')
export default {
  name: 'financialModule',
  components: {
    [Sticky.name]: Sticky,
    [Tab.name]: Tab,
    [Picker.name]: Picker,
    [Col.name]: Col,
    [Row.name]: Row,
    [Tabs.name]: Tabs,
    [Button.name]: Button,
    [TreeSelect.name]: TreeSelect,
    [Popup.name]: Popup,
    [DatetimePicker.name]: DatetimePicker,
  },
  watch: {
    financeTimeBegin(newVal, oldVal) {
      if (this.financeTimeEnd && this.financeTimeEnd <= newVal) {
        responseUtil.alertMsg(this, '开始时间不能晚于结束时间')
        this.financeTimeBegin = oldVal
      }
    },
    financeTimeEnd(newVal, oldVal) {
      if (this.financeTimeBegin && this.financeTimeBegin >= newVal) {
        responseUtil.alertMsg(this, '结束时间不能早于于开始时间')
        this.financeTimeEnd = oldVal
      }
    },
    time5(newVal, oldVal) {
      if (this.time6 && this.time6 <= newVal) {
        responseUtil.alertMsg(this, '开始时间不能晚于结束时间')
        this.time5 = oldVal
      }
    },
    time6(newVal, oldVal) {
      if (this.time5 && this.time5 >= newVal) {
        responseUtil.alertMsg(this, '结束时间不能早于于开始时间')
        this.time6 = oldVal
      }
    },
    time9(newVal, oldVal) {
      if (this.time10 && this.time10 <= newVal) {
        responseUtil.alertMsg(this, '开始时间不能晚于结束时间')
        this.time9 = oldVal
      }
    },
    time10(newVal, oldVal) {
      if (this.time9 && this.time9 >= newVal) {
        responseUtil.alertMsg(this, '结束时间不能早于于开始时间')
        this.time10 = oldVal
      }
    },
  },
  filters: {
    formatDateTime(value) {
      if (null == value || '' == value) return
      let date = new Date(value)
      let y = date.getFullYear()
      let MM = date.getMonth() + 1
      MM = MM < 10 ? '0' + MM : MM
      let d = date.getDate()
      d = d < 10 ? '0' + d : d
      let h = date.getHours()
      h = h < 10 ? '0' + h : h
      let m = date.getMinutes()
      m = m < 10 ? '0' + m : m
      let s = date.getSeconds()
      s = s < 10 ? '0' + s : s
      return y + '-' + MM + '-' + d
    },
    formatYearMonth(value) {
      if (null == value || '' == value) return
      let date = new Date(value)
      let y = date.getFullYear()
      let MM = date.getMonth() + 1
      MM = MM < 10 ? '0' + MM : MM
      let d = date.getDate()
      d = d < 10 ? '0' + d : d
      let h = date.getHours()
      h = h < 10 ? '0' + h : h
      let m = date.getMinutes()
      m = m < 10 ? '0' + m : m
      let s = date.getSeconds()
      s = s < 10 ? '0' + s : s
      return y + '-' + MM
    },
  },
  data() {
    return {
      NumberOfArticles: 0,
      AmountOfMoney: 0,
      checkedIndex: 0,
      departmentName: '',
      departmentId: '', //部门id
      functionId: '1032',
      companyList: [],
      activeId: -1,
      activeIndex: 0,
      items: [
        {
          text: '浙江',
          children: [
            { text: '杭州', id: 1 },
            { text: '温州', id: 2 },
          ],
        },
        {
          text: '辽宁',
          children: [
            { text: '沈阳', id: 3 },
            { text: '大连', id: 4 },
          ],
        },
        {
          text: '江苏',
          children: [
            { text: '南京', id: 5 },
            { text: '无锡', id: 6 },
          ],
        },
      ],
      isShowProjectMenu: false, //控制下拉菜单显示与否标识
      projectName: '部门检索',
      projectDataInfo: [],
      active: 0,
      currentDate: new Date(),
      isCheckInDateShow: false,
      timeIndex: '',
      houseTimeBegin: new Date(),

      houseTimeEnd: new Date(),
      financeTimeBegin: addMonth(new Date(), 0),
      financeTimeEnd: new Date(),
      time5: new Date(),
      time6: new Date(),
      time7: new Date(),
      time8: addMonth(new Date(), 0),
      time9: addMonth(new Date(), 0),
      time10: new Date(),
      time11: new Date(),
      //装修费
      decorationFee: {},
      //维修费
      maintenanceFee: {},
      //配货费
      distributionFee: {},
      //保洁费
      cleaningfee: {},
      shouldOrActual: true,
      shareOrNot: true,
      testimg:
        'image://https://himihome.oss-cn-qingdao.aliyuncs.com/mobile/d249a0ac-8984-4323-bac1-fd52d2781804.png',
      //新的列表接口所需数据
      departmentAuthData: {},
    }
  },
  mounted() {
    //房屋账单 折线图
    // this.houseBill()
    //房屋账单 饼状图
    //this.houseBillPie()
    // this.financialManagementIn()
    // this.financialManagementOut()
    //this.collectionType()
    // this.dailyInOut()
    // this.decoration()
    //公司初始化
    this.queryAuthLevelDepartment()
  },
  methods: {
    queryAuthLevelDepartment() {
      const that = this
      let initData = {}
      initData.staff_id = getStaffId() //员工id
      initData.function_id = that.functionId //方法模块id
      queryAuthLevelDepartment(initData).then(res =>
        responseUtil.dealResponse(that, res, () => {
          that.departmentAuthData = res.data.data
          let allList = that.departmentAuthData.list
          if (allList != null) {
            that.companyList = allList.filter(item => item.level == 1)
          }
          let departmentId = that.departmentAuthData.roleDepartmentId
          that.departmentId = departmentId
          let item = that.departmentAuthData.list.find(
            item => item.id == departmentId
          )
          that.departmentName = item ? item.departmentName || '' : ''
          that.initOtherDepartment()
          that.searchDepartmentInfo()
        })
      )
    },
    initOtherDepartment() {
      let that = this
      let allList = that.departmentAuthData.list
      let departmentId = that.departmentId
      let threeLevelDepartments = allList.filter(item => item.level == 3)
      let fiveLevelDepartments = allList.filter(item => item.level == 5)
      let levelDepartments = threeLevelDepartments
        .filter(item => {
          let levelTop = item.levelTop || ''
          return levelTop.indexOf(departmentId) != -1
        })
        .map(item => {
          let result = {}
          result.children = fiveLevelDepartments
            .filter(child => {
              let levelTop = child.levelTop || ''
              return levelTop.indexOf(',' + item.id) != -1
            })
            .map(child => {
              let childResult = {}
              childResult.text = child.departmentName
              childResult.id = child.id
              childResult.level = child.level
              return childResult
            })
          result.text = item.departmentName
          result.value = item.id
          result.level = item.level
          return result
        })
      that.items = levelDepartments // 公司列表
    },
    resetDepartmentInfo() {
      let departmentId = this.departmentAuthData.roleDepartmentId
      let item = this.departmentAuthData.list.find(
        item => item.id == departmentId
      )
      this.departmentName = item ? item.departmentName || '' : ''
      this.departmentId = departmentId
      this.isShowProjectMenu = false
      this.searchDepartmentInfo()
    },

    //选择公司，联动下方区、部
    selectCompany(item, index) {
      let that = this
      let roleLevel = this.departmentAuthData.roleLevel || 0
      let roleDepartmentId = this.departmentAuthData.roleDepartmentId || ''
      if (roleDepartmentId != item.id && Number(item.level) < roleLevel) {
        this.isShowProjectMenu = false
        responseUtil.alertErrMsg(that, '权限不足')
        return
      }
      this.checkedIndex = index
      this.departmentId = item.id
      this.activeId = this.departmentId
      this.departmentName = item.departmentName
      this.initOtherDepartment()
    },
    selectArea(value) {
      let item = this.items[value]
      let roleLevel = this.departmentAuthData.roleLevel || 0
      let roleDepartmentId = this.departmentAuthData.roleDepartmentId || ''
      if (roleDepartmentId != item.value && Number(item.level) < roleLevel) {
        this.isShowProjectMenu = false
        responseUtil.alertErrMsg(this, '权限不足')
        return
      }
      this.departmentId = item.value
      this.departmentName = item.text
      this.activeId = this.departmentId
    },
    selectDepartment(data) {
      let roleLevel = this.departmentAuthData.roleLevel || 0
      let roleDepartmentId = this.departmentAuthData.roleDepartmentId || ''
      if (roleDepartmentId != data.value && Number(data.level) < roleLevel) {
        this.isShowProjectMenu = false
        responseUtil.alertErrMsg(this, '权限不足')
        return
      }
      this.departmentId = data.id
      this.departmentName = data.text
    },
    TotalMoney() {
      const that = this
      let initData = {}
      initData.billStatus = '10'
      initData.billFeeType_id = '8'
      initData.roleType = '1'
      initData.staff_id = ''
      initData.level = '3'
      initData.deparementId = that.departmentId
      TotalMoney(initData).then(function(response) {
        responseUtil.dealResponse(that, response, () => {
          that.NumberOfArticles = response.data.data.NumberOfArticles // 条数
          that.AmountOfMoney = money(response.data.data.AmountOfMoney) // 逾期钱数
        })
      })
    },
    searchDepartmentInfo() {
      this.getHousesBill() //房屋账单
      this.getRenovationFund() //装修回款
      this.getReceivablePay() //应支，应收
      this.getReceivablePayDay() //日应收应支
      this.getAfterRent() //租后费用
      this.TotalMoney() // 逾期
      this.isShowProjectMenu = false
    },
    //显示下拉菜单
    showProject() {
      this.isShowProjectMenu = true
    },
    //取消选择
    cancelChangeProject() {
      this.projectName = '集寓项目名称'
      this.projectId = ''
      this.isShowProjectMenu = false
    },
    //状态切换
    pressButton(i) {
      // 1 应收 2 实收 3 合租 4 整租
      switch (i) {
        case 1:
          this.shouldOrActual = true
          break
        case 2:
          this.shouldOrActual = false
          break
        case 3:
          this.shareOrNot = true
          break
        case 4:
          this.shareOrNot = false
          break
      }
    },
    houseBill(housesBillDate, housesBillList, receiptsBillList) {
      let myChart = echarts.init(document.getElementById('houseBill'))
      // 绘制图表
      myChart.setOption({
        color: [
          '#19d4ae',
          '#5ab1ef',
          '#fa6e86',
          '#ffb980',
          '#0067a6',
          '#c4b4e4',
          '#d87a80',
          '#9cbbff',
          '#d9d0c7',
          '#87a997',
          '#d49ea2',
          '#5b4947',
          '#7ba3a8',
        ],
        tooltip: {
          trigger: 'axis',
        },
        legend: {
          data: ['支出', '收入'],
          width: '60%',
          left: '3%',
        },
        grid: {
          left: '3%',
          right: '4%',
          bottom: '3%',
          containLabel: true,
        },
        xAxis: {
          //show: false,
          type: 'category',
          boundaryGap: false,
          axisLabel: {
            interval: 0,
            rotate: -30,
          },
          //data: ['周一', '周二', '周三', '周四', '周五', '周六', '周日']
          data: housesBillDate,
        },
        yAxis: {
          type: 'value',
          axisLine: {
            show: false,
          },
          axisTick: {
            show: false,
          },
        },
        series: [
          {
            name: '支出',
            type: 'line',
            smooth: true,
            //stack: '总量',
            //data: [120, 132, 101, 134, 90, 230, 210],
            data: housesBillList,
          },
          {
            name: '收入',
            type: 'line',
            smooth: true,
            //stack: '总量',
            //data: [220, 182, 191, 234, 290, 330, 310],
            data: receiptsBillList,
          },
        ],
      })
    },
    //获取房屋账单数据
    getHousesBill() {
      var that = this
      let data = {}
      data.departmentId = that.departmentId //userid
      data.time = util.dateFormat(that.houseTimeBegin)
      data.afterTime = util.dateFormat(that.houseTimeBegin)
      housesBill(data).then(function(response) {
        responseUtil.dealResponse(that, response, () => {
          if (that.shouldOrActual) {
            //后六个月应支
            that.afterHousesBillList = [
              response.data.data.nowMax[0].nowMax,
              response.data.data.nowMax[0].afterOne,
              response.data.data.nowMax[0].afterTwo,
              response.data.data.nowMax[0].afterThree,
              response.data.data.nowMax[0].afterFour,
              response.data.data.nowMax[0].afterFive,
            ]
            if (JSON.stringify(response.data.data.nowMax[0]) == '{}') {
              that.afterHousesBillList = [0, 0, 0, 0, 0, 0]
            }
            //后六个月应收
            that.afterReceivableBillList = [
              response.data.data.nowMaxReceivable[0].nowMax,
              response.data.data.nowMaxReceivable[0].afterOne,
              response.data.data.nowMaxReceivable[0].afterTwo,
              response.data.data.nowMaxReceivable[0].afterThree,
              response.data.data.nowMaxReceivable[0].afterFour,
              response.data.data.nowMaxReceivable[0].afterFive,
            ]
            if (
              JSON.stringify(response.data.data.nowMaxReceivable[0]) == '{}'
            ) {
              that.afterReceivableBillList = [0, 0, 0, 0, 0, 0]
            }

            //后六个月的具体月份
            that.afterHousesBillDate = [
              response.data.data.now.substring(0, 7),
              response.data.data.afterOne.substring(0, 7),
              response.data.data.afterTwo.substring(0, 7),
              response.data.data.afterThree.substring(0, 7),
              response.data.data.afterFour.substring(0, 7),
              response.data.data.afterFive.substring(0, 7),
            ]

            that.houseBill(
              that.afterHousesBillDate,
              that.afterHousesBillList,
              that.afterReceivableBillList
            )
          } else {
            //前六个月
            that.housesBillList = [
              response.data.data.nowMin[0].five,
              response.data.data.nowMin[0].four,
              response.data.data.nowMin[0].three,
              response.data.data.nowMin[0].two,
              response.data.data.nowMin[0].one,
              response.data.data.nowMin[0].nowMin,
            ]
            that.receiptsBillList = [
              response.data.data.nowMinReceipts[0].five,
              response.data.data.nowMinReceipts[0].four,
              response.data.data.nowMinReceipts[0].three,
              response.data.data.nowMinReceipts[0].two,
              response.data.data.nowMinReceipts[0].one,
              response.data.data.nowMinReceipts[0].nowMin,
            ]
            if (JSON.stringify(response.data.data.nowMin[0]) == '{}') {
              that.housesBillList = [0, 0, 0, 0, 0, 0]
            }
            if (JSON.stringify(response.data.data.nowMinReceipts[0]) == '{}') {
              that.receiptsBillList = [0, 0, 0, 0, 0, 0]
            }

            //前六个月的具体月份
            that.housesBillDate = [
              response.data.data.five.substring(0, 7),
              response.data.data.four.substring(0, 7),
              response.data.data.three.substring(0, 7),
              response.data.data.two.substring(0, 7),
              response.data.data.one.substring(0, 7),
              response.data.data.now.substring(0, 7),
            ]

            that.houseBill(
              that.housesBillDate,
              that.housesBillList,
              that.receiptsBillList
            )
          }
        })
      })
    },

    //装修回款
    getRenovationFund() {
      var that = this
      let data = {}
      data.departmentId = that.departmentId
      // data.beginTime = util.dateFormat(that.time9)
      // data.endTime = util.getCurrentMonthLast(that.time10)
debugger
      if (that.shareOrNot) {
        data.houseType = '1' // 合租
      } else {
        data.houseType = '0' // 整租
      }

      renovationFund(data).then(function(response) {
        responseUtil.dealResponse(that, response, () => {
          that.renovationFund =
            response.data.data.renovationFund.renovation[0].renovation
          that.returnRenovation =
            response.data.data.returnRenovation.returnPrice
          if (
            JSON.stringify(response.data.data.renovationFund.renovation[0]) ==
            '{}'
          ) {
            that.renovationFund = 0
          }
          that.renovationData = [that.returnRenovation, that.renovationFund]

          that.decoration(that.renovationData)
        })
      })
    },
    //日应收支
    getReceivablePayDay() {
      var that = this
      let data = {}

      data.time = util.dateFormat(that.time7)
      data.departmentId = that.departmentId
      //data.endTime = util.dateFormat(that.time10)
      receivablePayDay(data).then(function(response) {
        responseUtil.dealResponse(that, response, () => {
          if (JSON.stringify(response.data.data.receivablePayDay[0]) == '{}') {
            that.receivablePayDay = [0, 0]
            that.dailyInOut(that.receivablePayDay)
          } else {
            that.receivablePayDay = [
              response.data.data.receivablePayDay[0].pay,
              response.data.data.receivablePayDay[0].receivable,
            ]

            that.dailyInOut(that.receivablePayDay)
          }
        })
      })
    },

    //租后费用
    getAfterRent() {
      var that = this
      let data = {}
      data.departmentId = that.departmentId
      data.beginTime = util.dateFormat(that.time8)
      data.endTime = util.getCurrentMonthLast(that.time11)
      afterRent(data).then(function(response) {
        responseUtil.dealResponse(that, response, () => {
          if (JSON.stringify(response.data.data.afterRent[0]) == '{}') {
            that.decorationFee = 0
            that.maintenanceFee = 0
            that.distributionFee = 0
            that.cleaningfee = 0
          } else {
            that.decorationFee = fmoney(
              response.data.data.afterRent[0].renovation,
              2
            )
            that.maintenanceFee = fmoney(
              response.data.data.afterRent[0].repair,
              2
            )
            that.distributionFee = fmoney(
              response.data.data.afterRent[0].distribution,
              2
            )
            that.cleaningfee = fmoney(response.data.data.afterRent[0].clean, 2)
          }
        })
      })
    },

    //应支，应收
    getReceivablePay() {
      var that = this
      let data = {}
      data.departmentId = that.departmentId
      data.beginTime = util.dateFormat(that.financeTimeBegin)
      data.endTime = util.getCurrentMonthLast(that.financeTimeEnd)
      receivablePay(data).then(function(response) {
        responseUtil.dealResponse(that, response, () => {
          if (JSON.stringify(response.data.data) != '{}') {
            that.receivableData = [
              { value: response.data.data.receivable.rent, name: '房租' },
              { value: response.data.data.receivable.deposit, name: '押金' },
              {
                value: response.data.data.receivable.waterAndElectricityReserve,
                name: '水电',
              },
              { value: response.data.data.receivable.lateFee, name: '滞纳' },
              { value: response.data.data.receivable.interest, name: '利息' },
              {
                value: response.data.data.receivable.penaltyForVreachOfContract,
                name: '违约',
              },
              { value: response.data.data.receivable.renterrent, name: '租金' },
              { value: response.data.data.receivable.hotwater, name: '热水' },
              { value: response.data.data.receivable.chanelFee, name: '渠道' },
              { value: response.data.data.receivable.serviceFee, name: '服务' },
              {
                value: response.data.data.receivable.earnestMoney,
                name: '定金',
              },
              {
                value: response.data.data.receivable.finalRenovation,
                name: '装修',
              },
              { value: response.data.data.receivable.repair, name: '维修' },
              { value: response.data.data.receivable.clean, name: '保洁' },
              {
                value: response.data.data.receivable.distribution,
                name: '配货',
              },
              { value: response.data.data.receivable.transport, name: '运送' },
              { value: response.data.data.receivable.broadband, name: '宽带' },
            ]
          } else {
            that.receivableData = [{ value: 0, name: '应收' }]
          }
          if (JSON.stringify(response.data.data) != '{}') {
            that.payableData = [
              { value: response.data.data.payable.rent, name: '房租' },
              { value: response.data.data.payable.deposit, name: '押金' },
              {
                value: response.data.data.payable.waterAndElectricityReserve,
                name: '水电',
              },
              { value: response.data.data.payable.lateFee, name: '滞纳' },
              { value: response.data.data.payable.interest, name: '利息' },
              {
                value: response.data.data.payable.penaltyForVreachOfContract,
                name: '违约',
              },
              { value: response.data.data.payable.renterrent, name: '租金' },
              { value: response.data.data.payable.hotwater, name: '热水' },
              { value: response.data.data.payable.chanelFee, name: '渠道' },
              { value: response.data.data.payable.serviceFee, name: '服务' },
              { value: response.data.data.payable.earnestMoney, name: '定金' },
              {
                value: response.data.data.payable.finalRenovation,
                name: '装修',
              },
              { value: response.data.data.payable.repair, name: '维修' },
              { value: response.data.data.payable.clean, name: '保洁' },
              { value: response.data.data.payable.distribution, name: '配货' },
              { value: response.data.data.payable.transport, name: '运送' },
              { value: response.data.data.payable.broadband, name: '宽带' },
            ]
          } else {
            that.payableData = [{ value: 0, name: '应支' }]
          }

          that.financialManagementIn(that.receivableData)
          //应支
          that.financialManagementOut(that.payableData)
        })
      })
    },
    // houseBillPie() {
    //     let myChart = echarts.init(document.getElementById('houseBillPie'));
    //     // 绘制图表
    //     myChart.setOption({
    //         color: ["#19d4ae", "#5ab1ef", "#fa6e86", "#ffb980", "#0067a6", "#c4b4e4", "#d87a80", "#9cbbff", "#d9d0c7", "#87a997", "#d49ea2", "#5b4947", "#7ba3a8"],
    //         title: {
    //             left: 220,
    //             top: 50,
    //             text: '房屋账单分类占比',
    //             textStyle: {
    //                 fontSize: 13
    //             }
    //         },
    //         tooltip: {
    //             trigger: 'item',
    //             formatter: '{a} <br/>{b}: {c} ({d}%)'
    //         },
    //         legend: {
    //             orient: 'vertical',
    //             left: 220,
    //             top: 70,
    //             data: ['直接访问', '邮件营销', '联盟广告', '视频广告', '搜索引擎'],
    //             textStyle: {
    //                 color: '#d8d8d8'
    //             },
    //             icon: 'circle'
    //         },
    //         graphic: { // 一个图形元素，类型是 group，可以嵌套子节点。
    //             type: 'group',
    //             left: 65,
    //             top: 110,
    //             children: [
    //                 {
    //                     type: 'text',
    //                     style: {
    //                         text: '累计应收',
    //                         textAlign: 'center',
    //                         fill: '#d8d8d8',
    //                         font: 'bolder 0.4rem "Microsoft YaHei", sans-serif'
    //                     }
    //                 },
    //                 {
    //                     type: 'text',
    //                     top: 20,
    //                     style: {
    //                         text: '7284717',
    //                         textAlign: 'center',
    //                         font: 'bolder 0.5rem "Microsoft YaHei", sans-serif'
    //                     }
    //                 },

    //             ]
    //         },
    //         series: [
    //             {
    //                 name: '访问来源',
    //                 type: 'pie',
    //                 radius: ['50%', '70%'],
    //                 center: ['30%','50%'],
    //                 avoidLabelOverlap: false,
    //                 label: {
    //                     show: false,
    //                     position: 'center'
    //                 },
    //                 labelLine: {
    //                     show: false
    //                 },
    //                 data: [
    //                     {value: 335, name: '直接访问'},
    //                     {value: 310, name: '邮件营销'},
    //                     {value: 234, name: '联盟广告'},
    //                     {value: 135, name: '视频广告'},
    //                     {value: 1548, name: '搜索引擎'}
    //                 ]
    //             }
    //         ]
    //     })
    // },

    /*点击箭头，增加或减少一个月*/
    leftArrow(paramType) {
      if (paramType == 1) {
        //房屋账单
        let date = new Date(this.houseTimeBegin)
        this.houseTimeBegin = this.reduceDate(date)
        this.getHousesBill()
      } else if (paramType == 2) {
        //房屋账单
        let date = new Date(this.houseTimeBegin)
        this.houseTimeBegin = this.addDate(date)
        this.getHousesBill()
      } else if (paramType == 3) {
        //财务管理  最小时间  3
        let date = new Date(this.financeTimeBegin)
        this.financeTimeBegin = this.reduceDate(date)
        this.getReceivablePay()
      } else if (paramType == 4) {
        //财务管理  最大时间  4
        let date = new Date(this.financeTimeEnd)
        this.financeTimeEnd = this.addDate(date)
        this.getReceivablePay()
      } else if (paramType == 5) {
        //日收支时间 5
        let date = new Date(this.time7)
        this.time7 = util.dateAddDay(date, -1)
        this.getReceivablePayDay()
      } else if (paramType == 6) {
        //日收支时间 6
        let date = new Date(this.time7)
        this.time7 = util.dateAddDay(date, 1)
        this.getReceivablePayDay()
      } else if (paramType == 7) {
        //租后费用 最大时间  7
        let date = new Date(this.time8)
        this.time8 = this.reduceDate(date)
        this.getAfterRent()
      } else if (paramType == 8) {
        //租后费用 最大时间  8
        let date = new Date(this.time11)
        this.time11 = this.addDate(date)
        this.getAfterRent()
      } else if (paramType == 9) {
        //装修回款 最大时间  9
        let date = new Date(this.time9)
        this.time9 = this.reduceDate(date)
        this.getRenovationFund()
      } else if (paramType == 10) {
        //装修回款 最大时间  10
        let date = new Date(this.time10)
        this.time10 = this.addDate(date)
        this.getRenovationFund()
      }
    },
    //显示时间弹层
    checkInDate(i) {
      this.isCheckInDateShow = true
      this.timeIndex = i
    },
    checkInDateConfirm() {
      switch (this.timeIndex) {
        case 1:
          this.houseTimeBegin = this.currentDate
          this.getHousesBill() //房屋账单
          break
        case 2:
          this.houseTimeEnd = this.currentDate
          break
        case 3:
          this.financeTimeBegin = this.currentDate
          this.getReceivablePay() //应支应收
          break
        case 4:
          this.financeTimeEnd = this.currentDate
          this.getReceivablePay() //应支应收
          break
        case 5:
          this.time5 = this.currentDate
          this.getReceivablePayDay() //日应支应收
          break
        case 6:
          this.time6 = this.currentDate
          this.getReceivablePayDay() //日应支应收
          break
        case 7:
          this.time7 = this.currentDate
          this.getReceivablePayDay() //日应支应收
          break
        case 8:
          this.time8 = this.currentDate
          this.getAfterRent() //租后费用
          break
        case 9:
          this.time9 = this.currentDate
          this.getRenovationFund() //装修回款
          break
        case 10:
          this.time10 = this.currentDate
          this.getRenovationFund() //装修回款
          break
        case 11:
          this.time11 = this.currentDate
          this.getAfterRent() //租后费用
          break
      }
      this.isCheckInDateShow = false
    },

    //使年月格式的时间减少一个月
    reduceDate(dateTime) {
      if (dateTime.getMonth() + 1 > 1) {
        let year = `${dateTime.getFullYear()}`
        let month = `${dateTime.getMonth() + 1 - 1}`
        if (month < 10) {
          month = `0${dateTime.getMonth() + 1 - 1}`
        }
        return year + '-' + month
      } else {
        let year = `${dateTime.getFullYear() - 1}`
        let month = 12
        return year + '-' + month
      }
    },

    //使年月格式的时间增加一个月
    addDate(dateTime) {
      if (dateTime.getMonth() + 1 > 11) {
        let year = `${dateTime.getFullYear() + 1}`
        let month = `01`
        return year + '-' + month
      } else {
        let year = `${dateTime.getFullYear()}`
        let month = `${dateTime.getMonth() + 1 + 1}`
        if (month < 10) {
          month = `0${dateTime.getMonth() + 1 + 1}`
        }
        return year + '-' + month
      }
    },
    //应收空心图
    financialManagementIn(data) {
      let myChart = echarts.init(
        document.getElementById('financialManagementIn')
      )
      // 绘制图表
      myChart.setOption({
        color: [
          '#19d4ae',
          '#5ab1ef',
          '#fa6e86',
          '#ffb980',
          '#0067a6',
          '#c4b4e4',
          '#d87a80',
          '#9cbbff',
          '#d9d0c7',
          '#87a997',
          '#d49ea2',
          '#5b4947',
          '#7ba3a8',
        ],
        title: {
          left: 200,
          top: 20,
          text: '应收占比',
          textStyle: {
            fontSize: 13,
          },
        },
        tooltip: {
          trigger: 'item',
          formatter: '{a} <br/>{b}: {c} ({d}%)',
        },
        legend: {
          orient: 'vertical',
          left: 200,
          top: 50,
          formatter: function(name) {
            var totalNum = 0
            for (var i = 0; i < data.length; i++) {
              totalNum += Number(data[i].value)
            }
            var res = ''
            for (var i = 0; i < data.length; i++) {
              if (data[i].name == name) {
                res = data[i].value
              }
            }
            if (totalNum == 0 || res == 0) {
              return name + '  (0 / 0%)'
            } else {
              return (
                name +
                '  (' +
                res +
                ' / ' +
                Number((res / totalNum) * 100).toFixed(2) +
                '%)'
              )
            }
          },
          //data: ['直接访问1', '邮件营销', '联盟广告', '视频广告', '搜索引擎'],
          textStyle: {
            color: '#d8d8d8',
          },
          icon: 'circle',
        },

        //             graphic: { // 一个图形元素，类型是 group，可以嵌套子节点。
        //                 type: 'group',
        //                 left: 65,
        //                 top: 110,
        //                 children: [
        //                     {
        //                         type: 'text',
        //                         style: {
        //                             text: '应收',
        //                             textAlign: 'center',
        //                             fill: '#d8d8d8',
        //                             font: 'bolder 0.4rem "Microsoft YaHei", sans-serif'
        //                         }
        //                     },
        //                     {
        //                         type: 'text',
        //                         top: 20,

        //                         style: {
        //                             text: '总量',
        //                             textAlign: 'center',
        //                             font: 'bolder 0.5rem "Microsoft YaHei", sans-serif'
        //                         }
        //                     },
        //                 ]
        //             },
        series: [
          {
            name: '应收',
            type: 'pie',
            radius: ['55%', '35%'],
            center: ['30%', '50%'],
            avoidLabelOverlap: false,
            label: {
              show: false,
              position: 'center',
            },
            labelLine: {
              show: false,
            },
            emphasis: {
              label: {
                show: true,
                fontSize: '15',
                fontWeight: 'bold',
              },
            },
            // data: [
            //     {value: 335, name: '直接访问'},
            //     {value: 310, name: '邮件营销'},
            //     {value: 234, name: '联盟广告'},
            //     {value: 135, name: '视频广告'},
            //     {value: 1548, name: '搜索引擎'}
            // ]
            data: data,
          },
        ],
      })
    },

    //应支空心图
    financialManagementOut(data) {
      let myChart = echarts.init(
        document.getElementById('financialManagementOut')
      )
      // 绘制图表
      myChart.setOption({
        color: [
          '#19d4ae',
          '#5ab1ef',
          '#fa6e86',
          '#ffb980',
          '#0067a6',
          '#c4b4e4',
          '#d87a80',
          '#9cbbff',
          '#d9d0c7',
          '#87a997',
          '#d49ea2',
          '#5b4947',
          '#7ba3a8',
        ],
        title: {
          left: 200,
          top: 20,
          text: '应支占比',
          textStyle: {
            fontSize: 13,
          },
        },
        tooltip: {
          trigger: 'item',
          formatter: '{a} <br/>{b}: {c} ({d}%)',
        },
        legend: {
          orient: 'vertical',
          left: 200,
          top: 50,
          formatter: function(name) {
            var totalNum = 0
            for (var i = 0; i < data.length; i++) {
              totalNum += Number(data[i].value)
            }
            var res = ''
            for (var i = 0; i < data.length; i++) {
              if (data[i].name == name) {
                res = data[i].value
              }
            }

            if (totalNum == 0 || res == 0) {
              return name + '  (0 / 0%)'
            } else {
              return (
                name +
                '  (' +
                res +
                ' / ' +
                Number((res / totalNum) * 100).toFixed(2) +
                '%)'
              )
            }
          },
          //data: ['直接访问', '邮件营销', '联盟广告', '视频广告', '搜索引擎'],
          textStyle: {
            color: '#d8d8d8',
          },
          icon: 'circle',
        },
        // graphic: { // 一个图形元素，类型是 group，可以嵌套子节点。
        //     type: 'group',
        //     left: 65,
        //     top: 110,
        //     children: [
        //         {
        //             type: 'text',
        //             style: {
        //                 text: '应支',
        //                 textAlign: 'center',
        //                 fill: '#d8d8d8',
        //                 font: 'bolder 0.4rem "Microsoft YaHei", sans-serif'
        //             }
        //         },
        //         {
        //             type: 'text',
        //             top: 20,
        //             style: {
        //                 text: '7284717',
        //                 textAlign: 'center',
        //                 font: 'bolder 0.5rem "Microsoft YaHei", sans-serif'
        //             }
        //         },

        //     ]
        // },
        series: [
          {
            name: '应支',
            type: 'pie',
            radius: ['55%', '35%'],
            center: ['30%', '50%'],
            avoidLabelOverlap: false,
            label: {
              show: false,
              position: 'center',
            },
            labelLine: {
              show: false,
            },

            emphasis: {
              label: {
                show: true,
                fontSize: '15',
                fontWeight: 'bold',
              },
            },
            // data: [
            //     {value: 335, name: '直接访问'},
            //     {value: 310, name: '邮件营销'},
            //     {value: 234, name: '联盟广告'},
            //     {value: 135, name: '视频广告'},
            //     {value: 1548, name: '搜索引擎'}
            // ]
            data: data,
          },
        ],
      })
    },
    // collectionType() {
    //     let myChart = echarts.init(document.getElementById('collectionType'));
    //     // 绘制图表
    //     myChart.setOption({
    //         color: ["#19d4ae", "#5ab1ef", "#fa6e86", "#ffb980", "#0067a6", "#c4b4e4", "#d87a80", "#9cbbff", "#d9d0c7", "#87a997", "#d49ea2", "#5b4947", "#7ba3a8"],
    //         title: {
    //             left: 220,
    //             top: 50,
    //             text: '各类型收款占比',
    //             textStyle: {
    //                 fontSize: 13
    //             }
    //         },
    //         tooltip: {
    //             trigger: 'item',
    //             formatter: '{a} <br/>{b} : {c} ({d}%)'
    //         },
    //         legend: {
    //             orient: 'vertical',
    //             left: 220,
    //             top: 70,
    //             data: ['直接访问', '邮件营销', '联盟广告', '视频广告', '搜索引擎'],
    //             textStyle: {
    //                 color: '#d8d8d8'
    //             },
    //             icon: 'circle'
    //         },
    //         series: [
    //             {
    //                 name: '访问来源',
    //                 type: 'pie',
    //                 radius: '55%',
    //                 center: ['30%', '50%'],
    //                 label: {
    //                     show: false
    //                 },
    //                 data: [
    //                     {value: 335, name: '直接访问'},
    //                     {value: 310, name: '邮件营销'},
    //                     {value: 234, name: '联盟广告'},
    //                     {value: 135, name: '视频广告'},
    //                     {value: 1548, name: '搜索引擎'}
    //                 ]
    //             }
    //         ]
    //     })
    // },

    //日应收支
    dailyInOut(data) {
      let myChart = echarts.init(document.getElementById('dailyInOut'))
      // 绘制图表
      myChart.setOption({
        grid: {
          top: '20%',
          left: '10%',
          right: '25%',
          height: '80%',
          containLabel: true,
        },
        xAxis: [
          {
            show: false,
            type: 'value',
          },
        ],
        yAxis: [
          {
            type: 'category',
            data: [
              {
                value: '当日应支',
                textStyle: {
                  fontSize: 14,
                  fontWeight: 700,
                },
              },
              {
                value: '当日应收',
                textStyle: {
                  fontSize: 14,
                  fontWeight: 700,
                },
              },
            ],
            axisLine: {
              show: false,
            },
            axisTick: {
              show: false,
            },
          },
        ],
        series: [
          {
            itemStyle: {
              normal: {
                // 定制显示（按顺序）
                color: function(params) {
                  var colorList = ['#ffc652', '#5ab1ef']
                  return colorList[params.dataIndex]
                },
                formatter: function(a, b, c) {
                  return c + '%'
                },
              },
            },
            label: {
              show: true,
              position: [170, 2],
              color: 'black',
              fontWeight: 900,
              fontSize: 15,
            },
            barCategoryGap: '50%',
            type: 'bar',
            //data: [41231.21,341241.21]
            data: data,
          },
        ],
      })
    },
    decoration(data) {
      let that = this
      let myChart = echarts.init(document.getElementById('decoration'))
      // 绘制图表
      myChart.setOption({
        // toolbox: {
        //   itemSize: 30,
        //   iconStyle: {
        //
        //   },
        //   showTitle: false,
        //   right: 5,
        //   feature: {
        //     myTool1: {
        //       show: true,
        //       height: 15,
        //       // title: '合租',
        //       icon: 'image://data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAEYAAAAYCAIAAADIyBxUAAAACXBIWXMAABYlAAAWJQFJUiTwAAAAEXRFWHRTb2Z0d2FyZQBTbmlwYXN0ZV0Xzt0AAANnSURBVFiF1ZhbSBRhFMf/c1nygqGwiulDZoGXQvPyYK0i9eBmGRaidDEUdkFfogUL14heilSoXB8KNC3aDILQJAtaQUxw6cEUfXBTyNRqLdPSLHFzd2Z6mGXv6u7Mutbv6Ztvzvm+85855zDzESaTCW6Q74bI8WHCOIWlBYJj3Q22EG5bMBcZw8UnsSlZ3PYIdwPCRRLV00H1PCPmZgIVoSiYA3nMkVNczE7HSbsk4tME3aYhPxi2IjZRWIormLwS26VVEmkYlNy9ij8ekvC/gDl0wnLmPD8mARAf30vuiNZTpMT9XjR3QybfwFKhhrYf2n4o1KJ2dIDq7aTb7/FjGoCkrQGr4vTI5JCXgJaAliAxDXqdB4OyKgSFAMDCHOouwDAIANUa7M30sODoW9SrfAqBevWE3ZXIpufQVPdTYnJMiAwbyRkoqQSApmvIzodMjsV5tLc42eh1Vp3VGjhXMxbm0HTdqpBfreKKsEDozger6Tkk1dMhzN8pguBQPLwFvQ71KswaIS/ZOP02AeLLNPWmmyR+fBO+hkwOVS0AaGrsyVZTigkDFGoUKf0Qpo+QA720cG+FGrkFME6hptT1Vr0KCjUKyxEuRWud3diGuhEAjFNYnBcegCdIwxDBnZP57MfX+soyzGZExaxpZpwCgJBQe6nY6sQ247/2YMP3t1SkxLGz0Ousj39DFGpcvImXj9HegqQ0hIXj16KTgf/aA4/vktpbXLvZ+rTW2cXv2QdagohIqBvR98Lbh+IjImqptg2xcRvYOOaPTI7dyfj9E+ZVjI84lZZfESEJ8NwbeNzzJzsfK8uY/wppNF53YfYzUg9iecn60lyYmRYclDhJ3lOkREIq9DpIo60zfAJXa/xbS2zCfhKhYaLj9YJwKcZHNql4HGEzc2nm8EmqS7vZOwVADAAuXMrkHictBaVcVGwA9gsATGE5CIIGSVlKVZLbl4SsERsHbf96ButUuWPDdG8PEZFo7rZ+N3oHk32Uyc4H3x7YpHSz8rKk5YaXznZ86ngurOUoCDYz11JWxY/tP+rk6AD9qIH4PuvHnQIDk1dsKa60XTofp6ya6OdaqqcDFvMWhOY7bGIak3+aTc5wnHQ9IQIA0wo12EeMDZMzk1haAPtvHXohKISL3MHFJzMpWVxcgvv9vwcQQ/yZ1ws/AAAAAElFTkSuQmCC',
        //       onclick: function (){
        //         alert('myToolHandler1')
        //         that.testimg = 'image://https://himihome.oss-cn-qingdao.aliyuncs.com/mobile/d249a0ac-8984-4323-bac1-fd52d2781804.png'
        //         that.decoration()
        //       }
        //     },
        //     myTool2: {
        //       show: true,
        //       title: '整租',
        //       icon: that.testimg,
        //       onclick: function (){
        //         alert('myToolHandler2')
        //         console.log(this.model.option.icon)
        //         that.testimg = 'image://https://himihome.oss-cn-qingdao.aliyuncs.com/images/platform/templatetmp/df40728a-810a-4ae6-9314-f0c98e2fd16a.jpg'
        //         console.log(that.testimg)
        //         that.decoration()
        //       }
        //     }
        //   }
        // },
        grid: {
          top: '30%',
          left: '10%',
          right: '40%',
          height: '80%',
          containLabel: true,
        },
        xAxis: [
          {
            show: false,
            type: 'value',
          },
        ],
        yAxis: [
          {
            type: 'category',
            data: [
              {
                value: '回款总额',
                textStyle: {
                  fontSize: 14,
                  fontWeight: 500,
                },
              },
              {
                value: '装修总额',
                textStyle: {
                  fontSize: 14,
                  fontWeight: 500,
                },
              },
            ],
            axisLine: {
              show: false,
            },
            axisTick: {
              show: false,
            },
          },
        ],
        series: [
          {
            itemStyle: {
              normal: {
                // 定制显示（按顺序）
                color: function(params) {
                  var colorList = ['#ffc652', '#5ab1ef']
                  return colorList[params.dataIndex]
                },
              },
            },
            label: {
              show: true,
              position: [140, 2],
              color: 'black',
              fontWeight: 500,
              fontSize: 15,
            },
            barCategoryGap: '50%',
            type: 'bar',
            data: data,
          },
        ],
      })
    },
  },
}
</script>

<style scoped>
/*项目选择模块*/
.module-small {
  width: 92%;
  height: 51px;
  margin: 0 auto;
  margin-top: 20px;
  background-color: white;
  border-radius: 8px;
  line-height: 51px;
}
/*项目名称样式*/
.project-name {
  font-size: 15px;
  color: #222222;
  margin-left: 20px;
  font-weight: bold;
}
/*下拉菜单右侧图标样式*/
.drop-down-image {
  float: right;
  margin-right: 0.5rem;
  margin-top: 25px;
  /*padding-bottom: 4px;*/
}
.choose-company-text {
  font-size: 0.4rem;
  font-weight: 700;
  color: black;
  padding: 10px 0 0 5px;
  font-size: 20px;
}
/*公司字体样式 未选中*/
.company-col-style {
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
}
/*公司字体样式 被选中*/
.company-col-checked-style {
  width: 2rem;
  text-align: center;
  background-color: #d3d3d3;
  border-radius: 0.1rem;
  font-size: 17px;
}
.search {
  margin: 15px 10px;
}
/*公司字体样式*/
.company-text-style {
  width: 2rem;
  text-align: center;
  /*background-color:#f7f8fa;*/
  border-radius: 0.1rem;
  font-size: 17px;
}
.button {
  position: absolute;
  z-index: 99;
  height: auto;
  padding: 2px 0;
  top: 8px;
  width: 60px;
  background-color: #ff5d3b;
  border-radius: 15px;
  color: white;
  font-size: 14px;
  text-align: center;
}
.button-no {
  background-color: #d8d8d8;
}
.title {
  padding: 10px 15px 5px;
  display: flex;
  align-items: center;
}
.redtitle {
  color: #ff5d3b;
  font-size: 0.37333rem;
  font-weight: bolder;
}
.blacktitle {
  color: black;
  font-size: 14px;
  font-weight: 600;
  padding: 0 2px;
}
.left-triangle {
  width: 0px;
  height: 0px;
  margin-right: 10px;
  margin-left: auto;
  border-width: 5px;
  border-style: solid;
  border-color: transparent black transparent transparent;
}
.right-triangle {
  width: 0px;
  height: 0px;
  margin-left: 10px;
  border-width: 5px;
  border-style: solid;
  border-color: transparent transparent transparent black;
}
.chart-content {
  margin: 0 15px;
  border-radius: 8px;
  width: 345px;
  height: 250px;
  background-color: white;
}
.chart-content1 {
  margin: 0 15px;
  border-radius: 8px;
  width: 345px;
  height: 480px;
  background-color: white;
}
.red-dividingLine {
  border-bottom: #ff5d3b dashed 2px;
  margin: 0 15px;
  width: 345px;
}
.lattices {
  justify-content: center;
  margin: 0 15px;
  width: 345px;
  background-color: white;
  border-radius: 8px;
  display: flex;
  flex-flow: row wrap;
}
.lattice {
  margin: 15px 0;
  width: 40%;
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
}
.lattice div {
  font-size: 22px;
  font-weight: bolder;
}
.lattice span {
  color: #d8d8d8;
  font-size: 13px;
}
</style>
