<template>
  <div class="decoration-statistics">
    <!--项目选择模块-->
    <div class="module-small" @click="showProject">
      <span class="project-name">{{ projectName }}</span>
      <img class="drop-down-image" src="../../assets/images/drop-down.png" />
    </div>
    <!--拉下菜单-->
    <van-popup v-model="isShowProjectMenu" position="bottom">
      <van-picker
        show-toolbar
        :columns="projectDataInfo"
        value-key="projectName"
        @setColumnValues=""
        @cancel="cancelChangeProject"
        @confirm="selectProject"
      />
    </van-popup>

    <!--项目情况-->
    <div class="module-literal">
      <!--统计标题-->
      <div>
        <span class="left-title">
          项目情况
        </span>
        <div class="right-time">
          <img
            @click="leftArrow(1)"
            class="left-arrow"
            src="../../assets/images/left-arrow.png"
          />
          <span class="min-date" @click="chooseTime(1)">{{
            projectInfoMinDate
          }}</span>
          <span class="middle-character">至</span>
          <span class="max-date" @click="chooseTime(2)">{{
            projectInfoMaxDate
          }}</span>
          <img
            @click="leftArrow(2)"
            class="right-arrow"
            src="../../assets/images/right-arrow.png"
          />
        </div>
      </div>
      <!--特征内容-->
      <div class="statistics-content-project">
        <div class="description-content">
          <div class="left-content">
            <div class="money-small-font">{{ projectInfo.newSignNum }}</div>
            <div class="description-text">新签量</div>
          </div>
          <!--中间分割线-->
          <div class="middle-line"></div>
          <div class="right-content">
            <div class="money-small-font">
              {{ projectInfo.continueSignNum }}
            </div>
            <div class="description-text">续租量</div>
          </div>
        </div>
        <div class="description-content">
          <div class="left-content">
            <div class="money-small-font">
              {{ projectInfo.normalCheckOutNum }}
            </div>
            <div class="description-text">正退量</div>
          </div>
          <!--中间分割线-->
          <div class="middle-line"></div>
          <div class="right-content">
            <div class="money-small-font">
              {{ projectInfo.breakContractNum }}
            </div>
            <div class="description-text">违约量</div>
          </div>
        </div>
      </div>
    </div>

    <!--出租率-->
    <!--        <div class="module-literal">-->
    <!--            &lt;!&ndash;统计标题&ndash;&gt;-->
    <!--            <div>-->
    <!--                <span class="left-title">-->
    <!--                    出租率-->
    <!--                </span>-->
    <!--                <div class="right-time">-->
    <!--                    <img @click="leftArrow(3)" class="left-arrow" src="../../assets/images/left-arrow.png" >-->
    <!--                    <span class="min-date" @click="chooseTime(3)">{{occupancyRateMinDate}}</span>-->
    <!--                    <span class="middle-character">至</span>-->
    <!--                    <span class="max-date" @click="chooseTime(4)">{{occupancyRateMaxDate}}</span>-->
    <!--                    <img @click="leftArrow(4)" class="right-arrow" src="../../assets/images/right-arrow.png">-->
    <!--                </div>-->
    <!--            </div>-->
    <!--            &lt;!&ndash;特征内容&ndash;&gt;-->
    <!--            <div class="statistics-content-figure">-->
    <!--                <div class="description-content">-->
    <!--                    <div class="money-big-font">{{occupancyRate.avgTimeLong}}</div>-->
    <!--                    <div class="description-text">平均时长/时</div>-->
    <!--                </div>-->
    <!--            </div>-->
    <!--        </div>-->
    <!--出租率仪表盘-->
    <div class="module-literal">
      <!--统计标题-->
      <div>
        <span class="left-title">
          出租率
        </span>
        <!-- <div class="right-time">
                    <img @click="leftArrow(5)" class="left-arrow" src="../../assets/images/left-arrow.png" >
                    <span class="min-date" @click="chooseTime(5)">{{occupancyRateChartMinDate}}</span>
                    <span class="middle-character">至</span>
                    <span class="max-date" @click="chooseTime(6)">{{occupancyRateChartMaxDate}}</span>
                    <img @click="leftArrow(6)" class="right-arrow" src="../../assets/images/right-arrow.png">
                </div> -->
      </div>
      <!--特征内容-->
      <div class="statistics-content-project">
        <ve-gauge
          :data="chartOccupancyRateData"
          width="7.7rem"
          height="6rem"
          style="left:35px;"
          :extend="occupancyRateSetend"
        ></ve-gauge>
        <div class="real-occupancy-rate">
          {{ realOccupancyRate + '%' }}
        </div>
      </div>
    </div>
    <!--平均房价-->
    <div class="module-literal">
      <!--统计标题-->
      <div>
        <span class="left-title">
          平均房价
        </span>
        <div class="right-time">
          <img
            @click="leftArrow(7)"
            class="left-arrow"
            src="../../assets/images/left-arrow.png"
          />
          <span class="min-date" @click="chooseTime(7)">{{
            avgHousePriceMinDate | formatYearMonth
          }}</span>
          <span class="middle-character">至</span>
          <span class="max-date" @click="chooseTime(8)">{{
            avgHousePriceMaxDate
          }}</span>
          <img
            @click="leftArrow(8)"
            class="right-arrow"
            src="../../assets/images/right-arrow.png"
          />
        </div>
      </div>
      <!--特征内容-->
      <div class="statistics-content-project">
        <ve-histogram
          :data="chartAvgHousePriceData"
          :settings="chartHousePriceSettings"
          :extend="avgHousePriceSetend"
          width="9rem"
          height="7rem"
          style="left:3px;"
        ></ve-histogram>
      </div>
    </div>
    <!--收入完成情况/百万-->
    <!--        <div class="module-literal">-->
    <!--            &lt;!&ndash;统计标题&ndash;&gt;-->
    <!--            <div>-->
    <!--                <span class="left-title">-->
    <!--                收入完成情况/百万-->
    <!--                </span>-->
    <!--                <div class="right-time">-->
    <!--                    <img @click="leftArrow(9)" class="left-arrow" src="../../assets/images/left-arrow.png" >-->
    <!--                    <span class="min-date" @click="chooseTime(9)">{{inComeFinishMinDate}}</span>-->
    <!--                    <span class="middle-character">至</span>-->
    <!--                    <span class="max-date" @click="chooseTime(10)">{{inComeFinishMaxDate}}</span>-->
    <!--                    <img @click="leftArrow(10)" class="right-arrow" src="../../assets/images/right-arrow.png">-->
    <!--                </div>-->
    <!--            </div>-->
    <!--            &lt;!&ndash;特征内容&ndash;&gt;-->
    <!--            <div class="statistics-content-project">-->

    <!--            </div>-->
    <!--        </div>-->
    <!--收入情况/百万-->
    <!--        <div class="module-literal">-->
    <!--            &lt;!&ndash;统计标题&ndash;&gt;-->
    <!--            <div>-->
    <!--                <span class="left-title">-->
    <!--                收入情况/百万-->
    <!--                </span>-->
    <!--                <div class="right-time">-->
    <!--                    <img @click="leftArrow(11)" class="left-arrow" src="../../assets/images/left-arrow.png" >-->
    <!--                    <span class="min-date" @click="chooseTime(11)">{{inComeMinDate}}</span>-->
    <!--                    <span class="middle-character">至</span>-->
    <!--                    <span class="max-date" @click="chooseTime(12)">{{inComeMaxDate}}</span>-->
    <!--                    <img @click="leftArrow(12)" class="right-arrow" src="../../assets/images/right-arrow.png">-->
    <!--                </div>-->
    <!--            </div>-->
    <!--            &lt;!&ndash;特征内容&ndash;&gt;-->
    <!--            <div class="statistics-content-project">-->
    <!--                <ve-histogram :data="chartIncomeData" :settings="chartIncomeSettings" :extend="incomeSetend"  width="9rem" height="7rem"></ve-histogram>-->
    <!--            </div>-->
    <!--        </div>-->

    <!--续约情况-->
    <div class="module-literal" style="height: 5rem">
      <!--统计标题-->
      <div>
        <span class="left-title">
          续约情况
        </span>
      </div>
      <!--特征内容-->
      <!-- <div class="statistics-content-figure" style="height: 3.2rem">
                <div class="left-content">
					<ve-pie class="chart-style" width="9rem" height="2.6rem" :data="chartRenewlContractData" :extend="renewlContractSetend"></ve-pie> 
					<div class="chart-style" id="chartId"></div>
				</div>
				 
            </div> -->
      <div class="basicPersonnelModuleCardBody">
        <div
          class="basicPersonnelModuleCardBody_backstageStyle"
          id="chartId"
        ></div>
      </div>
    </div>
    <!--时间选择-->
    <van-popup v-model="isDateShow" position="bottom">
      <van-datetime-picker
        v-model="currentDate"
        type="year-month"
        title="选择年月"
        item-height="35px"
        @confirm="dateConfirm"
        @cancel="isDateShow = false"
      />
    </van-popup>
  </div>
</template>

<script>
import { DatetimePicker, Picker, Popup } from 'vant'
import { responseUtil, addMonth } from '../../libs/rongxunUtil'
import {
  avgHousePrice,
  projectInfo,
  queryApartmentInfo,
  queryApartmentList,
  renewProportion,
} from '../../getData/getData'
import util from '../contract/util2'
var echarts = require('echarts')

export default {
  name: 'setMainAnalysis',
  components: {
    [Popup.name]: Popup,
    [DatetimePicker.name]: DatetimePicker,
    [Picker.name]: Picker,
  },
  filters: {
    formatYearMonth(value) {
      if (null == value || '' == value) return
      let date = new Date(value)
      let y = date.getFullYear()
      let MM = date.getMonth() + 1
      MM = MM < 10 ? '0' + MM : MM
      let d = date.getDate()
      d = d < 10 ? '0' + d : d
      let h = date.getHours()
      h = h < 10 ? '0' + h : h
      let m = date.getMinutes()
      m = m < 10 ? '0' + m : m
      let s = date.getSeconds()
      s = s < 10 ? '0' + s : s
      return y + '-' + MM
    },
  },
  data() {
    this.chartHousePriceSettings = {
      showLine: ['平均房价折线'],
    }
    this.chartIncomeSettings = {
      showLine: ['下单用户'],
    }

    return {
      projectInfo: {
        newSignNum: '232', //新签量
        continueSignNum: '232', //续签量
        normalCheckOutNum: '232', //正退量
        breakContractNum: '232', //违约量
      },

      occupancyRate: {
        avgTimeLong: '8', //出租率平均时长
      },

      isShowProjectMenu: false, //控制下拉菜单显示与否标识
      projectName: '集寓项目名称',
      projectDataInfo: [],

      totalCustomerNum: '31341', //当前总用户数

      //投诉情况
      complaintInfo: {
        complaintRate: '223', //投诉率
        complaints: '233', //投诉量
      },

      //投诉处理情况
      disposeComplaintInfo: {
        avgResponseTimeLong: '13.3',
        avgAcceptNum: '32',
        complaintSolveAging: '23',
      },
      chooseType: '', //选择类型 时长&总额 最小时间 or 验收情况  最小时间  等等
      currentDate: new Date(), //时间选择器绑定的时间
      isDateShow: false, //时间选择显示与否

      projectInfoMinDate: this.formatDate(new Date()), //项目情况 最小时间  1
      projectInfoMaxDate: this.formatDate(new Date()), //项目情况 最大时间  2
      occupancyRateMinDate: this.reduceDate(new Date()), //出租率  最大时间  3
      occupancyRateMaxDate: this.formatDate(new Date()), //出租率  最大时间  4
      occupancyRateChartMinDate: this.reduceDate(new Date()), //出租率仪表盘  最大时间  5
      occupancyRateChartMaxDate: this.formatDate(new Date()), //出租率仪表盘  最大时间  6
      realOccupancyRate: '0', //目标出租率
      //avgHousePriceMinDate:this.reduceDate(new Date()),        //平均房价  最大时间  7
      avgHousePriceMinDate: addMonth(new Date(), -5),
      avgHousePriceMaxDate: this.formatDate(new Date()), //平均房价  最大时间  8
      inComeFinishMinDate: this.reduceDate(new Date()), //收入完成情况  最大时间  9
      inComeFinishMaxDate: this.formatDate(new Date()), //收入完成情况  最大时间  10
      inComeMinDate: this.reduceDate(new Date()), //收入情况  最大时间  11
      inComeMaxDate: this.formatDate(new Date()), //收入情况  最大时间  12

      avgHousePriceMinDate2: addMonth(new Date(), -5),
      //avgHousePriceMinDate2: this.reduceDate2(new Date()),
      avgHousePriceMaxDate2: this.getMonthDate(new Date(), 2),
      /********************************出租率仪表盘样式 start **************************/

      occupancyRateSetend: {
        // dataType: {
        //     '占比': 'percent'
        // },
        // seriesMap: {
        //     '占比': {
        //         min: 0,
        //         max: 1
        //     }
        // },
        series: {
          // 大小
          // radius : ['48%', '60%'],
          radius: '70%',
          splitNumber: 10,
          axisLine: {
            show: true,
            lineStyle: {
              //轴线颜色
              color: [
                [0.2, '#FFC274'],
                [0.8, '#5AB1EF'],
                [1, '#FA6E86'],
              ],
              width: 14,
            },
          },
          //分割线样式
          splitLine: {
            show: true,
            length: 14,
          },
          //指针长度
          pointer: {
            show: true,
            length: '55%',
            wdith: 3,
          },
          //仪表盘指针样式
          // itemStyle:{},
          detail: {
            show: true,
            formatter: '{value} %',
            color: '#333',
            fontSize: 15,
            fontStyle: 'normal',
            fontWeight: '550',
            offsetCenter: ['140%', 30],
          },
          axisLabel: {
            show: true,
            fontSize: 8,
          },
        },
      },

      chartOccupancyRateData: {
        columns: ['type', 'value'],
        rows: [{ type: '占比', value: 50 }],
      },
      /********************************出租率仪表盘样式 end   **************************/

      /********************************平均房价样式 start **************************/
      avgHousePriceSetend: {
        legend: {
          show: false,
        },
        xAxis: {
          //X轴文字样式
          axisLabel: {
            show: true,
            color: '#000',
            fontSize: 8,
          },
        },
        yAxis: {
          position: 'left',
          axisLine: {
            show: true,
            lineStyle: {
              color: '#D8D8D8',
            },
          },
          //背景中的分割线样式
          splitLine: {
            show: true,
            lineStyle: {
              color: '#F3F3F3',
            },
          },

          splitNumber: 8, //y轴数值间隔
          show: true, //是否显示Y轴
        },

        series: {
          color: '#5AB1EF',
          barWidth: 8, //柱的宽度
          smooth: false,
          itemStyle: {
            borderType: 'dashed',
          },
        },
      },

      chartAvgHousePriceData: {
        columns: ['日期', '平均房价', '平均房价折线'],
        rows: [
          // { '日期': '2019/7', '访问用户': 1393, '下单用户': 1393},
          // { '日期': '2019/8', '访问用户': 3530, '下单用户': 3530},
          // { '日期': '2019/9', '访问用户': 2923, '下单用户': 2923},
          // { '日期': '2019/10', '访问用户': 1723, '下单用户': 1723},
          // { '日期': '2019/11', '访问用户': 3792, '下单用户': 3792},
          // { '日期': '2019/12', '访问用户': 4593, '下单用户': 4593}
        ],
      },
      /********************************平均房价样式 end   **************************/

      /********************************收入情况/百万样式 start **************************/
      incomeSetend: {
        legend: {
          show: false,
        },
        xAxis: {
          //X轴文字样式
          axisLabel: {
            show: true,
            color: '#000',
            fontSize: 8,
          },
        },
        yAxis: {
          type: 'value',
          position: 'left',
          axisLine: {
            show: true,

            lineStyle: {
              color: '#D8D8D8',
            },
          },
          //Y轴文字样式
          axisLabel: {
            fontSize: 10,
            formatter: '{value} %',
          },
          //背景中的分割线样式
          splitLine: {
            show: true,
            lineStyle: {
              color: '#F3F3F3',
            },
          },

          splitNumber: 8, //y轴数值间隔
          show: true, //是否显示Y轴
        },

        series: {
          color: '#FA6E86',
          barWidth: 8, //柱的宽度
          smooth: false, //折线的类型有没有弧度
          itemStyle: {
            borderType: 'dashed',
          },
        },
      },

      chartIncomeData: {
        columns: ['日期', '访问用户', '下单用户'],
        rows: [
          { 日期: '2019/7', 访问用户: 80, 下单用户: 80 },
          { 日期: '2019/8', 访问用户: 50, 下单用户: 50 },
          { 日期: '2019/9', 访问用户: 80, 下单用户: 80 },
          { 日期: '2019/10', 访问用户: 60, 下单用户: 60 },
          { 日期: '2019/11', 访问用户: 90, 下单用户: 90 },
          { 日期: '2019/12', 访问用户: 40, 下单用户: 40 },
        ],
      },
      /********************************收入情况/百万样式 end   **************************/

      /********************************续约情况样式 start **************************/
      // renewlContractSetend: {
      //     legend: {
      //         show:true,
      //         orient: 'vertical',
      //         left: 200,
      //         top:50,
      //         // data: ['直接访问', '邮件营销', '联盟广告', '视频广告', '搜索引擎'],
      //         textStyle: {
      //             color: '#d8d8d8'
      //         },
      //         icon: 'circle'
      //     },
      //     title: {
      //         left: 200,
      //         top: 20,
      //         text: '续约情况',
      //         textStyle: {
      //             fontSize: 13
      //         }
      //     },

      //     graphic: { // 一个图形元素，类型是 group，可以嵌套子节点。
      //         width: '30',
      //         type: 'group',
      //         left: 76,
      //         top: 120,
      //         children: [
      //             {
      //                 type: 'text',
      //                 left: 76,
      //                 top: 120,
      //                 style: {
      //                     width:'30',
      //                     text: '70%',
      //                     textAlign: 'center',
      //                     fill: '#FF5D3B',
      //                     font: 'bold 12px "Microsoft YaHei", sans-serif'
      //                 }
      //             },
      //         ]
      //     },

      //     series: {
      //         // 扇叶样式
      //         label: {
      //             normal: {
      //                 // 是否显示扇叶
      //                 show: false,
      //                 position: 'insideLeft',
      //                 // 文字大小
      //                 fontSize: 100
      //             }
      //         },
      //         // 位置
      //         center: ['22%', '60%'],
      //         // 大小
      //         radius : ['0', '70%'],
      //     }
      // },

      // chartRenewlContractData: {
      //     columns: ['name', 'value'],
      //     rows: [
      //         { 'name': '空置(343|8%)', 'value': 3530  },
      //         { 'name': '转租(343|8%)', 'value': 1393 },
      //     ]
      // },
      /********************************续约情况样式 end   **************************/
    }
  },
  mounted() {
    this.queryApartmentList()
    this.projectInfoData()
    this.queryApartmentInfo()
    this.avgHousePrice()
    this.renewProportion()
  },
  methods: {
    // 续租占比
    renewProportion() {
      const that = this
      let initData = {}
      // initData.user_id = globaluserId() //userid
      initData.apartmentId = that.projectId //userid
      // initData.beginTime = that.avgHousePriceMinDate  //userid
      // initData.endTime = that.avgHousePriceMaxDate  //userid
      debugger
      renewProportion(initData).then(function(response) {
        responseUtil.dealResponse(that, response, () => {
          debugger

          //that.chartRenewlContractData.rows = response.data.data.listNum;  // 续租
          let list = response.data.data.listNum
          that.renewlContractSetend(list)
        })
      })
    },
    //续约情况饼图
    renewlContractSetend(data) {
      let myChart = echarts.init(document.getElementById('chartId'))
      // 绘制图表
      myChart.setOption({
        color: [
          '#19d4ae',
          '#5ab1ef',
          '#fa6e86',
          '#ffb980',
          '#0067a6',
          '#c4b4e4',
          '#d87a80',
          '#9cbbff',
          '#d9d0c7',
          '#87a997',
          '#d49ea2',
          '#5b4947',
          '#7ba3a8',
        ],
        title: {
          left: 190,
          top: 30,
          text: '续约情况',
          textStyle: {
            fontSize: 13,
          },
        },
        tooltip: {
          trigger: 'item',
          formatter: '{a} <br/>{b} : {c} ({d}%)',
        },
        legend: {
          orient: 'vertical',
          left: 190,
          top: 59,
          formatter: function(name) {
            var totalNum = 0
            debugger
            for (var i = 0; i < data.length; i++) {
              totalNum += Number(data[i].value)
            }
            var res = ''
            for (var i = 0; i < data.length; i++) {
              if (data[i].name == name) {
                res = data[i].value
              }
            }

            if (totalNum == 0 || res == 0) {
              return name + '  (0 / 0%)'
            } else {
              return (
                name +
                '  (' +
                res +
                ' / ' +
                Number((res / totalNum) * 100).toFixed(2) +
                '%)'
              )
            }
          },
          //data: ['运营', '职能'],
          textStyle: {
            color: '#d8d8d8',
          },
          icon: 'circle',
        },
        series: [
          {
            name: '续约情况',
            type: 'pie',
            radius: '55%',
            center: ['30%', '50%'],
            label: {
              show: false,
            },
            // data: [
            //     {value: 335, name: '已结'},
            //     {value: 310, name: '未结'},
            // ]
            data: data,
          },
        ],
      })
    },

    // 平均房价
    avgHousePrice() {
      const that = this
      let initData = {}
      // initData.user_id = globaluserId() //userid
      initData.apartmentId = that.projectId //userid
      initData.beginTime = that.avgHousePriceMinDate2 //userid
      initData.endTime = that.avgHousePriceMaxDate2 //userid
      avgHousePrice(initData).then(function(response) {
        responseUtil.dealResponse(that, response, () => {
          that.chartAvgHousePriceData.rows = [
            {
              日期: response.data.data.beginDate.substring(2, 7),
              平均房价: response.data.data.begin.avgHousePrice,
              平均房价折线: response.data.data.begin.avgHousePrice,
            },
            {
              日期: response.data.data.oneDate.substring(2, 7),
              平均房价: response.data.data.one.avgHousePrice,
              平均房价折线: response.data.data.one.avgHousePrice,
            },
            {
              日期: response.data.data.twoDate.substring(2, 7),
              平均房价: response.data.data.two.avgHousePrice,
              平均房价折线: response.data.data.two.avgHousePrice,
            },
            {
              日期: response.data.data.threeDate.substring(2, 7),
              平均房价: response.data.data.three.avgHousePrice,
              平均房价折线: response.data.data.three.avgHousePrice,
            },
            {
              日期: response.data.data.fourDate.substring(2, 7),
              平均房价: response.data.data.four.avgHousePrice,
              平均房价折线: response.data.data.four.avgHousePrice,
            },
            {
              日期: response.data.data.endDate.substring(2, 7),
              平均房价: response.data.data.end.avgHousePrice,
              平均房价折线: response.data.data.end.avgHousePrice,
            },
          ]
        })
      })
    },
    // 出租率
    queryApartmentInfo() {
      const that = this
      let initData = {}
      // initData.user_id = globaluserId() //userid
      initData.apartmentId = that.projectId //userid
      initData.beginTime = that.occupancyRateChartMinDate //userid
      initData.endTime = that.occupancyRateChartMaxDate //userid
      queryApartmentInfo(initData).then(function(response) {
        responseUtil.dealResponse(that, response, () => {
          //目标出租率
          that.realOccupancyRate = response.data.data.occupancyRate //实际出租率
          //   that.chartOccupancyRateData.rows[0].value =
          //     response.data.data.targetOccupancyRate // 目标出租率
          // that.chartOccupancyRateData.rows[0].value = response.data.data.occupancyRate; // 实际出租率
          that.chartOccupancyRateData.rows[0].value =
            response.data.data.targetOccupancyRate //目标出租率
        })
      })
    },

    //项目列表初始化
    queryApartmentList() {
      const that = this
      let initData = {}
      // initData.user_id = globaluserId() //userid

      // initData.user_id = globaluserId() //userid
      queryApartmentList(initData).then(function(response) {
        responseUtil.dealResponse(that, response, () => {
          let list = response.data.data.apartmentProjectList
          //console.log(response.data.data.apartmentProjectList)
          that.projectDataInfo = list
        })
      })
    },

    //项目情况
    projectInfoData() {
      const that = this
      let initData = {}
      // initData.user_id = globaluserId() //userid
      initData.apartmentId = that.projectId //userid
      initData.beginTime = util.dateFormat(that.projectInfoMinDate) //userid
      initData.endTime = util.getCurrentMonthLast(that.projectInfoMaxDate) //userid

      projectInfo(initData).then(function(response) {
        responseUtil.dealResponse(that, response, () => {
          that.projectInfo.breakContractNum =
            response.data.data.breakContractNum // 违约
          that.projectInfo.normalCheckOutNum =
            response.data.data.normalCheckOutNum // 正退
          that.projectInfo.newSignNum = response.data.data.newSignNum // 新签
          that.projectInfo.continueSignNum = response.data.data.renewSignNum // 续签
        })
      })
    },

    //显示下拉菜单
    showProject() {
      this.isShowProjectMenu = true
    },

    //取消选择
    cancelChangeProject() {
      this.projectName = '集寓项目名称'
      this.projectId = ''
      this.isShowProjectMenu = false
      this.projectInfoData()
      this.queryApartmentInfo()
      this.avgHousePrice()
      this.renewProportion()
    },

    //选择项目
    selectProject(item) {
      this.projectName = item.projectName
      this.projectId = item.id
      this.projectInfoData()
      this.queryApartmentInfo()
      this.avgHousePrice()
      this.renewProportion()
      this.isShowProjectMenu = false
    },

    //选择时间
    chooseTime(value) {
      this.chooseType = value //时长&总额 最小时间  1
      this.isDateShow = true
    },

    /*时间格式转化方法*/
    formatDate(dateTime) {
      let year = `${dateTime.getFullYear()}`
      let month = `${dateTime.getMonth() + 1}`
      if (month < 10) {
        month = `0${dateTime.getMonth() + 1}`
      }
      return year + '-' + month
    },

    //确认时间
    dateConfirm(date) {
      this.currentDate = this.formatDate(date)
      if (this.chooseType == 1) {
        this.projectInfoMinDate = this.currentDate //项目情况 最小时间  1
        this.projectInfoData()
      } else if (this.chooseType == 2) {
        this.projectInfoMaxDate = this.currentDate //项目情况 最大时间  2
        this.projectInfoData()
      } else if (this.chooseType == 3) {
        this.occupancyRateMinDate = this.currentDate //出租率  最小时间  3
        this.queryApartmentInfo()
      } else if (this.chooseType == 4) {
        this.occupancyRateMaxDate = this.currentDate //出租率  最大时间  4
        this.queryApartmentInfo()
      } else if (this.chooseType == 5) {
        this.occupancyRateChartMinDate = this.currentDate //出租率仪表盘 最大时间  5
        this.queryApartmentInfo()
      } else if (this.chooseType == 6) {
        this.occupancyRateChartMaxDate = this.currentDate //出租率仪表盘 最大时间  6
        this.queryApartmentInfo()
      } else if (this.chooseType == 7) {
        this.avgHousePriceMinDate = this.currentDate //平均房价 最大时间  7

        let date2 = new Date(this.currentDate)
        this.avgHousePriceMinDate2 = this.getMonthDate(date2, 1)

        this.avgHousePrice()
      } else if (this.chooseType == 8) {
        this.avgHousePriceMaxDate = this.currentDate //平均房价 最大时间  8

        let date2 = new Date(this.currentDate)
        this.avgHousePriceMaxDate2 = this.getMonthDate(date2, 2)

        this.avgHousePrice()
      } else if (this.chooseType == 9) {
        this.inComeFinishMinDate = this.currentDate //收入完成情况 最大时间  9
      } else if (this.chooseType == 10) {
        this.inComeFinishMaxDate = this.currentDate //收入完成情况 最大时间  10
      } else if (this.chooseType == 11) {
        this.inComeMinDate = this.currentDate //收入情况 最小时间  11
      } else if (this.chooseType == 12) {
        this.inComeMaxDate = this.currentDate //收入情况 最大时间  12
      }
      this.isDateShow = false
    },

    /*点击箭头，增加或减少一个月*/
    leftArrow(paramType) {
      if (paramType == 1) {
        //项目情况 最小时间  1
        let date = new Date(this.projectInfoMinDate)
        this.projectInfoMinDate = this.reduceDate(date)
        this.projectInfoData()
      } else if (paramType == 2) {
        //项目情况 最大时间  2
        let date = new Date(this.projectInfoMaxDate)
        this.projectInfoMaxDate = this.addDate(date)
        this.projectInfoData()
      } else if (paramType == 3) {
        //出租率  最小时间  3
        let date = new Date(this.occupancyRateMinDate)
        this.occupancyRateMinDate = this.reduceDate(date)
      } else if (paramType == 4) {
        //出租率  最大时间  4
        let date = new Date(this.occupancyRateMaxDate)
        this.occupancyRateMaxDate = this.addDate(date)
      } else if (paramType == 5) {
        //出租率仪表盘 最大时间  5
        let date = new Date(this.occupancyRateChartMinDate)
        this.occupancyRateChartMinDate = this.reduceDate(date)
        this.queryApartmentInfo()
      } else if (paramType == 6) {
        //出租率仪表盘 最大时间  6
        let date = new Date(this.occupancyRateChartMaxDate)
        this.occupancyRateChartMaxDate = this.addDate(date)
        this.queryApartmentInfo()
      } else if (paramType == 7) {
        //平均房价 最大时间  7
        let date = new Date(this.avgHousePriceMinDate)
        this.avgHousePriceMinDate = this.reduceDate(date)
        //将选择的年月转化为年月日传入后台
        let date2 = new Date(this.avgHousePriceMinDate)
        this.avgHousePriceMinDate2 = this.getMonthDate(date2, 1)

        this.avgHousePrice()
      } else if (paramType == 8) {
        //平均房价 最大时间  8
        let date = new Date(this.avgHousePriceMaxDate)
        this.avgHousePriceMaxDate = this.addDate(date)

        let date2 = new Date(this.avgHousePriceMaxDate)
        this.avgHousePriceMaxDate2 = this.getMonthDate(date2, 2)

        this.avgHousePrice()
      } else if (paramType == 9) {
        //收入完成情况 最大时间  9
        let date = new Date(this.inComeFinishMinDate)
        this.inComeFinishMinDate = this.reduceDate(date)
      } else if (paramType == 10) {
        //收入完成情况 最大时间  10
        let date = new Date(this.inComeFinishMaxDate)
        this.inComeFinishMaxDate = this.addDate(date)
      } else if (paramType == 11) {
        //收入情况 最小时间  11
        let date = new Date(this.inComeMinDate)
        this.inComeMinDate = this.reduceDate(date)
      } else if (paramType == 12) {
        //收入情况 最大时间  12
        let date = new Date(this.inComeMaxDate)
        this.inComeMaxDate = this.addDate(date)
      }
    },

    //使年月格式的时间减少一个月
    reduceDate(dateTime) {
      if (dateTime.getMonth() + 1 > 1) {
        let year = `${dateTime.getFullYear()}`
        let month = `${dateTime.getMonth() + 1 - 1}`
        if (month < 10) {
          month = `0${dateTime.getMonth() + 1 - 1}`
        }
        return year + '-' + month
      } else {
        let year = `${dateTime.getFullYear() - 1}`
        let month = 12
        return year + '-' + month
      }
    },
    //使年月格式的时间减少一个月 返回年月日 第一天
    reduceDate2(dateTime) {
      if (dateTime.getMonth() + 1 > 1) {
        let year = `${dateTime.getFullYear()}`
        let month = `${dateTime.getMonth() + 1 - 1}`
        if (month < 10) {
          month = `0${dateTime.getMonth() + 1 - 1}`
        }

        return year + '-' + month + '-' + '01'
      } else {
        let year = `${dateTime.getFullYear() - 1}`
        let month = 12
        return year + '-' + month + '-' + '01'
      }
    },

    //使年月格式的时间增加一个月
    addDate(dateTime) {
      if (dateTime.getMonth() + 1 > 11) {
        let year = `${dateTime.getFullYear() + 1}`
        let month = `01`
        return year + '-' + month
      } else {
        let year = `${dateTime.getFullYear()}`
        let month = `${dateTime.getMonth() + 1 + 1}`
        if (month < 10) {
          month = `0${dateTime.getMonth() + 1 + 1}`
        }
        return year + '-' + month
      }
    },

    /*时间格式转化方法*/
    formatDate(dateTime) {
      let year = `${dateTime.getFullYear()}`
      let month = `${dateTime.getMonth() + 1}`
      if (month < 10) {
        month = `0${dateTime.getMonth() + 1}`
      }
      return year + '-' + month
    },

    //获取传入月份的月初和月末
    getMonthDate(date, status) {
      var now = date //获取传入日期
      var nowMonth = now.getMonth() //当前月
      var nowYear = now.getFullYear() //当前年
      //本月的开始时间
      var monthStartDate = new Date(nowYear, nowMonth, 1)
      //本月的结束时间
      var monthEndDate = new Date(nowYear, nowMonth + 1, 0)
      if (status == 1) {
        //将时间加八小时（时区问题）
        monthStartDate.setHours(monthStartDate.getHours() + 8)
        monthStartDate = util.dateFormat(monthStartDate)
        return monthStartDate
      }
      if (status == 2) {
        monthEndDate.setHours(monthEndDate.getHours() + 8)
        monthEndDate = util.dateFormat(monthEndDate)
        return monthEndDate
      }

      //var timeStar=Date.parse(monthStartDate)/1000;//s
      //var timeEnd=Date.parse(monthEndDate)/1000;//s
    },
  },
}
</script>

<style scoped>
/*背景颜色*/
.decoration-statistics {
  width: 10rem;
  background-color: #fafafa;
  /*height: 1840px;*/
}

/*项目选择模块*/
.module-small {
  width: 92%;
  height: 51px;
  margin: 0 auto;
  margin-top: 20px;
  background-color: white;
  border-radius: 8px;
  line-height: 51px;
}

/*项目名称样式*/
.project-name {
  font-size: 15px;
  color: #222222;
  margin-left: 20px;
  font-weight: bold;
}

/*下拉菜单右侧图标样式*/
.drop-down-image {
  float: right;
  margin-right: 0.5rem;
  margin-top: 25px;
  /*padding-bottom: 4px;*/
}

/*模块样式*/
.module-literal {
  width: 92%;
  margin: 0 auto;
  margin-top: 20px;
}

/*左上角标题*/
.left-title {
  font-size: 14px;
  font-family: PingFangSC-Semibold;
  color: #ff5d3b;
  font-weight: bolder;
  margin: 10px 0px 5px 0px;
}

/*右上角选择时间*/
.min-date,
.max-date,
.middle-character {
  font-size: 14px;
  font-weight: 600;
  padding: 0 4px;
}

/*模块主题内容*/
.statistics-content-figure {
  width: 100%;
  height: 109px;
  border-radius: 8px;
  background-color: white;
  margin-top: 5px;
  text-align: center;
}

/*项目情况模块*/
.statistics-content-project {
  width: 100%;
  height: 230px;
  border-radius: 8px;
  background-color: white;
  margin-top: 5px;
  text-align: center;
}

/*含有图表的模块*/
.module-charts {
  width: 92%;
  margin: 0 auto;
  margin-top: 20px;
}

/*右上角统一div样式*/
.right-time {
  float: right;
  margin-right: 8px;
}

/*所有数字下方描述性质字体*/
.description-text {
  font-size: 12px;
  color: #999999;
  font-family: PingFangSC-Regular;
  margin-top: 4px;
  font-weight: bolder;
}

/*数字样式-小*/
.money-small-font {
  font-family: Oswald-Regular;
  font-size: 20px;
  color: #222222;
  font-weight: bolder;
  padding-top: 30px;
}

/*数字样式-大*/
.money-big-font {
  font-family: Oswald-Regular;
  font-size: 40px;
  color: #222222;
  font-weight: bolder;
  padding-top: 20px;
}

/*中间分割线*/
.middle-line {
  float: left;
  width: 1px;
  height: 43px;
  left: 50%;
  margin-top: 33px;
  border-right: #ff5d3b 1px dashed;
}

/*图表样式*/
.chart-style {
  height: 115px;
  width: 115px;
}

.description-content {
  width: 85%;
  margin: 0 auto;
  height: 100px;
}

.description-content-other {
  width: 8.2rem;
  margin: 0 auto;
}

.left-content {
  float: left;
  width: 49.5%;
}

.right-content {
  /*margin-left: 20px;*/
  float: right;
  width: 49.5%;
}

.middle-content,
.first-content,
.third-content {
  /*margin-left: 20px;*/
  float: left;
  width: 33.33%;
}

.statistics-content-chart {
  width: 100%;
  border-radius: 8px;
  background-color: white;
  margin-top: 5px;
  text-align: center;
}

/*右上角选择时间左右箭头*/
.left-arrow,
.right-arrow {
  padding-bottom: 2px;
}

.basicPersonnelModuleCardBody {
  border-radius: 8px;
  background-color: #ffffff;
  width: 100%;

  margin-top: 5px;
  text-align: center;
}
.basicPersonnelModuleCardBody_backstageStyle {
  width: 345px;
  height: 155px;
}

/*目标出租率 */
.statistics-content-project {
  display: block;
  position: relative;
}
.statistics-content-project .ve-gauge {
  display: inline-block;
  left: 0 !important;
}
.real-occupancy-rate {
  position: absolute;
  width: 50px;
  text-align: center;
  color: #333;
  font-size: 16px;
  font-weight: 550;
  bottom: 1rem;
  left: calc(50% - 25px);
  text-align: center;
}
</style>
