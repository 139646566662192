import { render, staticRenderFns } from "./statisticsModule.vue?vue&type=template&id=04b5061a&scoped=true&"
import script from "./statisticsModule.vue?vue&type=script&lang=js&"
export * from "./statisticsModule.vue?vue&type=script&lang=js&"
import style0 from "./statisticsModule.vue?vue&type=style&index=0&id=04b5061a&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "04b5061a",
  null
  
)

export default component.exports